import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next';

// Assets
import Icon from '../assets/img/Website Assets/Icons/Fleche Swap - noir.png'
import NewsImage from '../assets/img/Website Assets/GTO Carousel/GTO 1.png'
import GTOImage from '../assets/img/Website Assets/GTO Carousel/GTO 2.png'
import CollectionImage from '../assets/img/Website Assets/Collection Pics/GTO Collection Computer.png'
import GTOFourImage from '../assets/img/Website Assets/GTO Carousel/GTO 4.png'
import FlecheImage from '../assets/img/Website Assets/Icons/Fleche.png'
import BurgerIcon from '../assets/img/Website Assets/Icons/burger-bar.png'
// import { Link } from 'react-router-dom'

const FAQ = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Adjust the threshold as needed
      const threshold = 670;

      if (documentHeight - scrollPosition - windowHeight < threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div class="container">
      <div className='p-5'>
        <h1 className='text-center mt-2'>Frequently Asked Questions</h1>
            <h4>VALUE-ADDED TAX CHARGES</h4>
        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              How is value-added tax Calculated?
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">In accordance with EU regulations, your purchase from 1-FAB web store will be taxed based on the VAT of the destination country.</div>
              <p>Prices listed on our web store include the applicable value-added tax rate for France, where our web store is based. For customers outside of France, the value-added tax rate displayed at checkout will be adjusted based on the shipping country.</p>
              <p>If your delivery address is in Norway and Switzerland or outside the European Union, the price on the checkout page will not include Value Added Tax (VAT). Upon arrival at the local customs, you will be responsible for paying any applicable taxes which are not included in the purchase price.</p>
            </div>
          </div>
        </div>







        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              As a business customer, can I make tax-exempt purchases?
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Not at this stage. </div>
            </div>
          </div>
        </div>



    <strong>Product Questions</strong>

        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
              What makes our products unique compared to others on the market? 
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Our products blend traditional craftsmanship with innovative technology, offering personalized, sustainable art pieces unlike anything else available.</div>
            </div>
          </div>
        </div>






        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
              How does our web store enhance the experience of our product?
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Our web store offers immersive personalization options, detailed product histories, and direct access to our creator’s information and links.</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
              How does the product personalization process work?
              </button>
            </h2>
            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">If you want specific product personalization, please reply to the emails we have sent you with the specific requires describing what you would like and will be get back you as soon as possible with a response as to whether it is possible and the price for such personalization.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
              Is your 3D-printed art actually bronze or just plastic?
              </button>
            </h2>
            <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Absolutely! Our pieces are 85% real bronze. The rest is biodegradable Polylactic Acid, sourced from renewables.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSeven">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
              Does it have the same weight as traditional bronze?
              </button>
            </h2>
            <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Indeed, we can adjust the weight based on your preferences during production, which may influence the final cost. Generally, the weight is equivalent to that of a traditionally bronze molded art piece.</div>
            </div>
          </div>
        </div>


        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEight">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
              Am I limited in character to write on the piece?
              </button>
            </h2>
            <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">No, as there are many places where you can write. Keep in mind the beauty of the piece when personalizing the piece.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingNine">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
              How customizable is the UCP?
              </button>
            </h2>
            <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">You can upload photos and videos, select a edition number (subject to availability), and pen a personal message.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTen">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
              Is it possible to remove the NFC chip from the piece?
              </button>
            </h2>
            <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">No, the NFC chip is integrated into the piece and can only be accessed if the piece is broken.</div>
            </div>
          </div>
        </div>


        <strong>Order Issues</strong>

        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading11">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
              Can I modify my order after I’ve placed it?
              </button>
            </h2>
            <div id="flush-collapse11" class="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">No, unfortunately we cannot modify orders once they have been placed. You may cancel an order before it is processed and place a new order. We're sorry for this inconvenience.</div>
            </div>
          </div>
        </div>


        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading12">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
              Can I cancel my order after I’ve placed it?
              </button>
            </h2>
            <div id="flush-collapse12" class="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">If you need to cancel an order and the order has not been put into production, we'll do our best to help.  Please contact our <Link target="_blank" to="https://1-art-app.myshopify.com/pages/contact">Customer Service</Link>. Read <Link target="_blank" to="/terms-and-service">Terms & Conditions</Link> for full details.</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading13">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
              I received the wrong item.
              </button>
            </h2>
            <div id="flush-collapse13" class="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">We are so sorry if you receive an incorrect order! Please contact our our <Link target="_blank" to="https://1-art-app.myshopify.com/pages/contact">Customer Service</Link> within 14 days of delivery with your order number and a picture of the incorrect product. We'll get back to you ASAP!</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading14">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
              How can I get an invoice for my order?
              </button>
            </h2>
            <div id="flush-collapse14" class="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">You will be able to download the updated invoice directly from your <Link target="_blank" to="https://1-art.co/account">account center</Link></div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading15">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
              What information is included in the invoice?
              </button>
            </h2>
            <div id="flush-collapse15" class="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">The invoice will include the order number, date, billing and shipping addresses, product name(s), quantity, price, and applicable taxes and fees.</div>
            </div>
          </div>
        </div>


<strong>Checkout & Payments</strong>
        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading16">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
              How can I pay for my order?
              </button>
            </h2>
            <div id="flush-collapse16" class="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">We accept all major credit cards including Visa, Mastercard, American Express, Carte Bleue, as well as Apple Pay, Shop Pay and PayPal.</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading17">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
              Do I have to create a 1-ART account to shop?
              </button>
            </h2>
            <div id="flush-collapse17" class="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">An account is required in order to shop from our web store. This makes it easy to check on the status of your orders, your collection (with the UCP for each art piece you own), to have access to the UCP of an art piece you own, and gives you access to your full order history. You can also save your shipping information for a quicker checkout experience.</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading18">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
              Can I get a refund if the price has changed?
              </button>
            </h2>
            <div id="flush-collapse18" class="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Unfortunately, we are unable to honor any promotions for orders placed outside of the promotional time frame as this would be unfair to the rest of our customers. We’re so sorry for any inconvenience and appreciate your understanding!</div>
            </div>
          </div>
        </div>





        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading19">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
              Will I be charged International Bank Fees?
              </button>
            </h2>
            <div id="flush-collapse19" class="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">When placing an order, the final total you see during checkout is in Euro currency. Once your bank pulls the funds, they will account for the current exchange rate. Your bank may or may not charge additional fees for international transactions. Please reach out to your bank for more information on any additional charges/fees.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading20">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
              Why am I getting a credit card error when purchasing online?
              </button>
            </h2>
            <div id="flush-collapse20" class="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">If you are using your credit card, please ensure that you enter the card information as well as the billing information correctly. If a payment fails, please contact your bank and verify the transaction.</div>
            </div>
          </div>
        </div>

        <strong>Shipping & Delivery</strong>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading21">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
              My package says delivered, but I didn’t receive it.
              </button>
            </h2>
            <div id="flush-collapse21" class="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Sometimes tracking information can be incorrect, and packages are marked as delivered before they actually arrive. Your package will most likely be delivered within a few days, but it’s great to check with your neighbors and/or shipping carrier for more information in the meantime! If you do not receive your order in a couple of days, please contact our <Link target="_blank" to="https://1-art-app.myshopify.com/pages/contact">Customer Service</Link> within 14 days of the last tracking update, with your order number and tracking information.</div>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading22">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
              When will my order ship?
              </button>
            </h2>
            <div id="flush-collapse22" class="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">In-stock orders usually ship within 1-3 business days. If not in stock, please add 3-5 business days.</div>
              <p>You may find the ETA time in your order summary via email or in your account.</p>
              <p>Once your order has shipped, you will receive a shipping confirmation via email with a tracking number.</p>
            </div>
          </div>
        </div>



        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading23">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
              How do I track the delivery of my order?
              </button>
            </h2>
            <div id="flush-collapse23" class="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Once your order ships, you will receive an email with a tracking number that you can use to track your package’s journey. You can also access the tracking number for your order in your account. It is located under your Account Details, where your full order history is accessible.</div>
            </div>
          </div>
        </div>




        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading24">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
              What should I do if my order hasn’t been delivered?
              </button>
            </h2>
            <div id="flush-collapse24" class="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">If your order is shipping to a domestic address, shipping typically takes 3-7 business days. If it is an international order, shipping may take 10-21 business days. Please note that business days do not include weekends or holidays.</div>
              <p>We recommend reaching out to the shipping carrier for more information on the status of your order. If your order has exceeded typical shipping times, please contact our <Link target="_blank" to="https://1-art-app.myshopify.com/pages/contact">Customer Service</Link>.</p>
            </div>
          </div>
        </div>


        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading25">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
              My order tracking isn't updating for many days?
              </button>
            </h2>
            <div id="flush-collapse25" class="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">We're so sorry for the delay with your package! Although tracking does not indicate this, we can assure you that your package is still in transit to you. If the tracking wasn't updated for more than 3 days, please contact our <Link target="_blank" to="https://1-art-app.myshopify.com/pages/contact">Customer Service</Link> or shipping carrier directly for further help.</div>
            </div>
          </div>
        </div>


        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading26">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
              What countries/regions do you ship to?
              </button>
            </h2>
            <div id="flush-collapse26" class="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">We currently ship globally.</div>
            </div>
          </div>
        </div>


        <strong>Returns & Refunds</strong>

        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading27">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
              I need my refund redirected to a different account.
              </button>
            </h2>
            <div id="flush-collapse27" class="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">Unfortunately, we are only able to issue a refund to the original payment method for the order. Please reach out to your bank for more information on how to reroute the funds to a different account.</div>
            </div>
          </div>
        </div>


        <div class="accordion accordion-flush my-4" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading28">
              <button class="accordion-button collapsed bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
              What happens if my package is returned after it is shipped?
              </button>
            </h2>
            <div id="flush-collapse28" class="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">If you return your order to us:</div>
              <p class="accordion-body">Please initiate a return process, contact us and send the required information to us. Please be aware you will be responsible for the cost of return shipping, duties and taxes.</p>
              <p>If your package was returned to us by a shipping carrier:</p>
              <p>We’re so sorry to hear that your purchases are coming back to us! You can use your tracking number to estimate when the package will return to us. We will issue a refund once we physically receive your order back at our warehouse and confirm that your package was undeliverable. If you would like to purchase the same products, you will need to place another order. We apologize for any inconvenience this may cause.</p>
              <p>Sometimes, if the courier didn't contact you or nobody was home at delivery, the courier may return the package. We recommend that you monitor the tracking status and keep the phone on at delivery. If the package has been returned, you may also contact the local courier directly, which is more efficient.</p>
              <strong>Return Policy</strong>
              <p>We want you to love your purchase, but if you are not completely satisfied, you are eligible for a return within 7 days from the day you receive the goods, unless the goods were made to order, in which case these products cannot be returned. Read <Link target="_blank" to="/terms-and-service">Terms & Conditions</Link> for full details.</p>
            </div>
          </div>
        </div>






      </div>
      <div id="fixedBottomContainer" style={{ position: 'relative' }}>
                <div class={`hidden_in_mobile my-5 ${isFixed ? 'fixed_bottom' : 'fixed_bottom'}`}>
                  <div class={`row ${menuVisible ? 'd-none' : 'information_toggle'}`}>
                    <div class="col-md-12 col-lg-12">
                      <div class="menu">
                        <ul class="d-flex justify-content-between list-unstyled m-0">
                          <li class="me-5 cursor_pointer">
                          <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                            <h6
                              class="m-0 fw-normal cursor_pointer"
                            >
                              {t('news_on_instragram')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                              >
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="container text-center my-3">
                                      <div
                                        class="row mx-auto my-auto justify-content-center"
                                      >
                                        <div
                                          id="recipeCarousel"
                                          class="carousel slide"
                                          data-bs-ride="carousel"
                                        >
                                          <div class="carousel-inner" role="listbox">
                                            <div class="carousel-item active">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={NewsImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={CollectionImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    {/* <img
                                                    src={teamImage}
                                                    class="img-fluid"
                                                  /> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOFourImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="prevContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="prev"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_left"
                                              />
                                            </a>
                                          </div>
                                          <div class="nextContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="next"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_right"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <h6
                              class="m-0 fw-normal cursor_pointer information_onClick"
                              onClick={toggleMenu}
                            >
                              {t('information')}
                            </h6>
                          </li>
                          <li class="mx-5">
                          <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                            <h6
                              class="m-0 fw-normal cursor_pointer"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal1"
                            >
                              {t('Contact')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal1"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>

                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Entreprise')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('reasonForRequest')}</label>
                                            <select
                                              class="form-select custom-select w_400 w-100"
                                              id="inputGroupSelect01"
                                            >
                                              <option value="1">{t('customerService')}</option>
                                              <option value="2">
                                                {t('pressCommunication')}
                                              </option>
                                              <option value="3">
                                                {t('partnerShip')}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label class="switch me-2" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t("conditions d'utilisation.")}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                          <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                            <h6 class="m-0 fw-normal cursor_pointer">
                              {t('artist')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal2"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('photos')}</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label
                                                class="switch me-2"
                                                for="checkbox2"
                                              >
                                                <input type="checkbox" id="checkbox2" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t(`conditions d'utilisation.`)}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5 d-flex justify-content-between list-unstyled m-0">
                              <h6 class="m-0 fw-normal" >{t('Suivre 1-ART')}</h6>
                              <li class="mx-3"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                  {menuVisible && (
                    <ul
                      class="d-flex justify-content-between list-unstyled information_menu"
                    >
                      <li class="me-5 cursor_pointer">
                        <h6 class="m-0 fw-normal cursor_pointer" onClick={toggleMenu}>{t('information')}</h6>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                          <h6 class="m-0 cursor_pointer">{t('order_tracing')}</h6>
                        </Link>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link className="text-decoration-none text-black" to="/faq">
                          <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                        </Link>
                      </li>

                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/privacy-policy">
                          <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                        </Link>
                      </li>
                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/terms-and-service">
                          <h6 class="m-0 cursor_pointer">
                            {t('termOfService')}
                          </h6>
                        </Link>
                      </li>
                    </ul>)}
                </div>
              </div>

      <div
              class="d-flex justify-content-center fixed-bottom bg-white hidden_in_desktop"
            >
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <span class="navbar-toggler-icon">
                  <img
                    src={BurgerIcon}
                    width="26"
                    height="25"
                  />
                </span>
              </button>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasTop"
                aria-labelledby="offcanvasTopLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close text-reset m-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">
                  <div class="menu">
                    <ul
                      class="d-flex flex-column align-items-center list-unstyled mt-5"
                    >
                      <li class="mb-5">
                      <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                        <h6
                          class="m-0 cursor_pointer"
                        >
                          {t('news_on_instragram')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="container text-center my-3">
                                  <div
                                    class="row mx-auto my-auto justify-content-center"
                                  >
                                    <div
                                      id="recipeCarousel m-0 p-0"
                                      class="carousel slide"
                                      data-bs-ride="carousel"
                                    >
                                      <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={NewsImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={CollectionImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                {/* <img
                                                src={teamImage}
                                                class="img-fluid"
                                              /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOFourImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="prevContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="prev"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_left"
                                          />
                                        </a>
                                      </div>
                                      <div class="nextContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="next"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_right"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <h6
                          class="m-0 cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalResponsive1"
                        >
                          {t('information')}
                        </h6>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive1"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered bg-white w_85"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <ul
                                  class="d-flex flex-column align-items-center list-unstyled"
                                >
                                  <li class="mb-5 cursor_pointer">
                                    <h6 class="m-0 fw-normal cursor_pointer">
                                      {t('information')}
                                    </h6>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('order_tracing')}
                                    </h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link className="text-decoration-none text-black" to="/faq">
                                    <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                                    </Link>
                                  </li>
                                 
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/privacy-policy"> 
                                    <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/terms-and-service">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('termOfService')}
                                    </h6>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                        <h6
                          class="m-0 cursor_pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModalResponsive2"
                        >
                          {t('Contact')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive2"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div
                                        class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                      >
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red">{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div
                                        class="d-flex justify-content-between mb-3"
                                      >
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>

                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('bussiness')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('reasonForRequest')}</label>
                                        <select
                                          class="form-select custom-select w_400 w-100"
                                          id="inputGroupSelect01"
                                        >
                                          <option value="1">{t('customerService')}</option>
                                          <option value="2">
                                            {t('pressCommunication')}
                                          </option>
                                          <option value="3">
                                            {t('partnerShip')}
                                          </option>
                                        </select>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Vente')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"
                                        ></textarea>
                                      </div>
                                      <div
                                        class="d-flex justify-content-between align-items-center mb-2 text-start"
                                      >
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox">
                                            <input type="checkbox" id="checkbox" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                        <h6
                          class="m-0 cursor_pointer">
                          {t('artist')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive3"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div class="transform_img_layer d-flex align-items-center position-absolute bottom-0">
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red"
                                          >{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div class="d-flex justify-content-between mb-3">
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Message')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('photos')}</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mb-2 text-start">
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox2">
                                            <input type="checkbox" id="checkbox2" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-3">
                        <h6 class="m-0 cursor_pointer">{t('Suivre 1-ART')}</h6>
                      </li>
                    </ul>
                    <ul class="d-flex justify-content-evenly list-unstyled">
                        <li class="mx-1"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{color:'#000'}}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{color:'#000'}}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{color:'#000'}}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{color:'#000'}}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{color:'#000'}}><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
      </div>
    </div>
  )
}

export default FAQ