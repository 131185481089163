import React, { useState } from 'react'
import APIService from '../networks/APIService'
import { useNavigate } from 'react-router-dom'
import Loader from './Loader/Loader'
import { toast } from 'react-toastify'
import { t } from 'i18next'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible,setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const validate = () => {
    if(email === '' ){
      toast(t('Email is required'))
    }else if (password === ''){
      toast(t('Password is required'))
    }
    else {
      loginUser()
    }
  }

  const loginUser = async () => {
    setLoading(true);
    try {
      const response = await APIService.login( email, password);
      if (response && response?.success) {
        toast(t('User sign-in successfully'))
        navigate('/home/product/1234')
        localStorage.setItem('user', JSON.stringify(response.data));
        window.location.reload();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      toast(t('Invalid email or password'))
      setLoading(false);
      console.error(error);
    }
  }
  return (
    <div class="login-bg w-sm-100 pt-5 pt-md-3 pb-5 d-flex align-items-center justify-content-center">
      <div class="bg-white login-box pt-5 pb-3 text-center">
        <img
          src="../assets/img/Website Assets/1-ART Logo/Logo - Point Rouge.png"
          class="login-logo-img mb-5"
        />
        <div class="w_75 mx-auto">
          <div class="d-flex justify-content-between mb-4">
          </div>

          <div class="position-relative mb-4">
            <label
              for="formGroupExampleInput"
              class="form-label bg-white start_15 position-absolute px-3"
            >{t('E-mail')}</label>
            <input
              type="text"
              class="form-control w_400 w-100"
              id="formGroupExampleInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div class="position-relative mb-3">
            <label
              for="formGroupExampleInput"
              class="form-label bg-white start_15 position-absolute px-3"
            >{t('password')}</label>
            <input
              type={passwordVisible ? "text" : "password"}
              class="form-control w_400 w-100"
              id="formGroupExampleInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <img src="../assets/img/Website Assets/Icons/Views - noir.png" class="pass_eye" onClick={()=>setPasswordVisible(!passwordVisible)} />
          </div>

          <button
            class="btn btn-register d-flex justify-content-around align-items-center mb-2 w-100"
            onClick={validate}
          >
            {loading ?<Loader />:
            <React.Fragment>
            <p class="lh-sm text-start m-0">
              {t('create_account')} <br />
              {t('to_login')}
            </p>
            <img src="../assets/img/Website Assets/Icons/Fleche.png" width="60" />
            </React.Fragment>
  }
          </button>

          <div class="mb-3 text-start hidden_in_mobile">
            <a
              href="reset_password.html"
              class="text-decoration-none fs-6 fw-bold"
            >{t('reset_password')}</a>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <hr class="w-50 fw-bold" />
            <p class="mx-3 m-0 fs-5">{t('ou')}</p>
            <hr class="w-50" />
          </div>

          <button
            class="btn btn-google d-flex justify-content-between align-items-center mb-3 px-3 w-100"
          >
            <div class="d-flex align-items-center">
              <p class="circle-shape me-3 m-0"></p>
              <p class="text-start m-0 fw-bold lh-sm">
                {t('login_with_name')} <br />
                name.firstname@gmail.com
              </p>
            </div>
            <img src="../assets/img/google.png" class="w_google" />
          </button>

          <button
            class="btn btn-facebook d-flex justify-content-start align-items-center mb-3 px-3 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-facebook fs-2 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with_facebook')}</p>
            </div>
          </button>

          <button
            class="btn btn-x d-flex justify-content-start align-items-center mb-3 ps-4 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-x-twitter fs-3 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with')}</p>
            </div>
          </button>

          <button
            class="btn btn-x d-flex justify-content-start align-items-center ps-4 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-apple fs-1 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with_apple')}</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login