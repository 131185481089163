import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import AddItems from "./components/Add_items";
import Sign_up from "./components/Sign_up";
import DataBase from "./components/DataBase";
import Login from "./components/login";
import Ma_collection from "./components/Ma_collection";
import TermsAndService from "./components/TermsAndService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FAQ from "./components/FAQ";

export default function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route element={<Home />} path="/" exact />
          <Route element={<DataBase />} path="/admin" />
          <Route element={<Ma_collection />} path="/collection" />
          <Route element={<TermsAndService />} path="/terms-and-service" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<FAQ />} path="/faq" />
          <Route path="/home/product/:nfcNumber" element={<Home />} />
          <Route path="/add_items" element={<AddItems />} />
          <Route path="/sign_up" element={<Sign_up />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);