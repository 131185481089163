import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageEN from "../translation/locale/en/en.translate.json";
import languageFR from "../translation/locale/fr/fr.translate.json";
import cookies from "js-cookie";

const lang =
  cookies.get("i18next") === "fr"
    ? "fr"
    : "en"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: languageEN },
      fr: { translation: languageFR },
    },
    lng: lang,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
  });

export default i18n;
