import React, { useState } from 'react'
import APIService from '../networks/APIService'
import { Link } from 'react-router-dom'
import Loader from './Loader/Loader'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const Sign_up = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible,setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleToggle = () => {
    setIsChecked(prevState => !prevState);
  };

  const handleToggle2 = () => {
    setIsChecked2(prevState => !prevState);
  };

  const validate = () => {
    if(firstName === ''){
      toast(t('firstNameRequired'))
    } else if(lastName === ''){
      toast(t('lastNameRequired'))
    }else if(email === ''){
      toast(t('Email is required'))
    }else if(password === ''){
      toast(t('Password is required'))
    }else if(!isChecked){
      toast(t('termNCondition'))
    }else{
      registerUser()
    }
  }

  const registerUser = async () => {
    setLoading(true)
    try {
      const response = await APIService.signup(firstName, lastName, email, password);
      if (response && response?.success) {
        toast(t('termNCondition'));
      }
      setLoading(false);
      localStorage.setItem('user', response.data);
    } catch (error) {
      setLoading(false);
      toast(error?.message || t('somethingWentWrong'))
      console.log(error);
    }
  }
  return (
    <div class="login-bg w-sm-100 pt-5 pt-md-3 pb-5 d-flex align-items-center justify-content-center">
      <div class="bg-white login-box pt-5 pb-3 text-center">
        <img
          src="../assets/img/Website Assets/1-ART Logo/Logo - Point Rouge.png"
          class="login-logo-img mb-5"
        />
        <div class="w_75 mx-auto">
          <div class="d-flex justify-content-between mb-4">
            <div class="position-relative">
              <label
                for="formGroupExampleInput"
                class="form-label bg-white start_15 position-absolute px-3"
              >{t('Nom')}</label>
              <input
                type="text"
                class="form-control w_184"
                id="formGroupExampleInput"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div class="position-relative">
              <label
                for="formGroupExampleInput"
                class="form-label bg-white start_15 position-absolute px-3">{t('firstName')}</label>
              <input
                type="text"
                class="form-control w_204"
                id="formGroupExampleInput"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div class="position-relative mb-4">
            <label
              for="formGroupExampleInput"
              class="form-label bg-white start_15 position-absolute px-3"
            >{t('E-mail')}</label>
            <input
              type="text"
              class="form-control w_400 w-100"
              id="formGroupExampleInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div class="position-relative mb-3">
            <label
              for="formGroupExampleInput"
              class="form-label bg-white start_15 position-absolute px-3"
            >{t('password')}</label>
            <input
              type={passwordVisible ? "text" : "password"}
              class="form-control w_400 w-100"
              id="formGroupExampleInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img src="../assets/img/Website Assets/Icons/Views - noir.png" class="pass_eye" onClick={()=>setPasswordVisible(!passwordVisible)}/>
          </div>

          <div
            class="d-flex justify-content-between align-items-center mb-2 text-start"
          >
            {/* <div class="w-auto">
              <label class="switch me-3" for="checkbox">
                <input type="checkbox" id="checkbox" />
                <div class="slider round"></div>
              </label>
            </div> */}
             <div>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <div class="slider round"></div>
      </label>
    </div>
            <p class="lh-sm switch_p m-0">
              {t('I read')}
              <a href="#" class="text-decoration-none">{t('terms')}</a>
              {t('term1')}
            </p>
          </div>

          <div class="d-flex mb-3 text-start">
            <div class="w-auto">
              <label class="switch me-3" for="checkbox2">
                <input type="checkbox" id="checkbox2" checked={isChecked2} onChange={handleToggle2} />
                <div class="slider round"></div>
              </label>
            </div>
            <p class="switch_p">
              {t('Je')}
            </p>
          </div>

          <button
            class="btn btn-register d-flex justify-content-around align-items-center mb-2 w-100"
            onClick={validate}
          >
            {loading ?
            <Loader />
            :
            <React.Fragment>
            <p class="lh-sm text-start m-0">
              {t('create_account')} <br />
              {t('to_login')}
            </p>
            <img src="../assets/img/Website Assets/Icons/Fleche.png" width="60" />
            </React.Fragment>
}
          </button>

          <div class="mb-3 text-start hidden_in_mobile">
            <a
              href="reset_password.html"
              class="text-decoration-none fs-6 fw-bold">{t('reset_password')}</a>
          </div>

          <div class="mb-3 text-start hidden_in_mobile">
            <Link
              to="/login"
              class="text-decoration-none fs-6 fw-bold">{t('Login')}</Link>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <hr class="w-50 fw-bold" />
            <p class="mx-3 m-0 fs-5">{t('ou')}</p>
            <hr class="w-50" />
          </div>

          <button
            class="btn btn-google d-flex justify-content-between align-items-center mb-3 px-3 w-100"
          >
            <div class="d-flex align-items-center">
              <p class="circle-shape me-3 m-0"></p>
              <p class="text-start m-0 fw-bold lh-sm">
                {t('login_with_name')} <br />
                name.firstname@gmail.com
              </p>
            </div>
            <img src="../assets/img/google.png" class="w_google" />
          </button>

          <button
            class="btn btn-facebook d-flex justify-content-start align-items-center mb-3 px-3 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-facebook fs-2 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with_facebook')}</p>
            </div>
          </button>

          <button
            class="btn btn-x d-flex justify-content-start align-items-center mb-3 ps-4 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-x-twitter fs-3 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with')}</p>
            </div>
          </button>

          <button
            class="btn btn-x d-flex justify-content-start align-items-center ps-4 w-100"
          >
            <div class="d-flex align-items-center">
              <i class="fa-brands fa-apple fs-1 me-3"></i>
              <p class="text-start m-0 fw-bold">{t('login_with_apple')}</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sign_up