import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from "react-i18next"

// Component 
import './index.css';
import App from './App';
import i18n from './translation/i18n';
import APIService from './networks/APIService';
const temp = localStorage.getItem('user');
const auth = JSON.parse(temp);


const root = ReactDOM.createRoot(document.getElementById('root'));
  // window.onunload = () => {
  //   handleBeforeUnload()
  // }
  
  const handleBeforeUnload = (event) => {
    APIService.resetUserToken(auth.email).then((response) => {
      console.log(response)
      localStorage.removeItem('user');
    }).catch((error) => {
      console.error(error);
    })
  
    event.preventDefault();
    event.returnValue = ''; 
  };
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
);