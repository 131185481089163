import dayjs from 'dayjs'
import { t } from 'i18next'
import React from 'react'
import Logo from '../assets/img/Website Assets/1-ART Logo/Logo - Point Rouge.png'

const OrderInvoices = (props) => {
  const { data, onClickClose } = props
  const temp = localStorage.getItem('user');
  const auth = JSON.parse(temp);
  return (
    <>
      <div class="modal fade" style={{ display: 'block', opacity: 1, }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content p-4 mt-5" style={{ borderRadius: "25px" }}>

            <div class="exit position-absolute d-flex justify-content-center align-items-center" style={{ borderRadius: "50px" }} onClick={onClickClose}>
              <i class="fa-solid fa-xmark cursor_pointer" aria-label="Close"></i>
            </div>

            <div class="container">
              <div class="header">
                {/* <h1>{t('1-ART-APP')}</h1> */}
                <img
                  src={Logo}
                  class="logo-img"
                />
                <div class="order-details">
                  <p>{t('Order')} #{data && data.length > 0 && data?.[0]?.orderId}</p>
                  <p>{data && data.length > 0 && dayjs(data?.[0]?.createdAt).format('MMM DD, YYYY')}</p>
                </div>
              </div>
              <div class="address-section d-flex">
                <div>
                  <div class="section-title">{t('SHIP TO')}</div>
                  <p>{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</p>
                </div>
                <div>
                  <div class="section-title">{t('BILL TO')}</div>
                  <p>{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</p>
                </div>
              </div>

              <hr />

              <table class="table table-borderless ">
                <thead>
                  <tr>
                    <th>{t('ITEMS')}</th>
                    <th className='text-end'>{t('QUANTITY')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 && data.map((item, index) => {
                    return (
                      <tr>
                        <td class="table-content">
                          <div class="d-flex align-items-center">
                            <img src={item?.productImage} alt="Item Image" className='invoice_img' />
                            <p class="ms-3 mb-0 text-center">{item?.name}</p>
                          </div>

                        </td>
                        <td className='text-end'>{item?.quantity}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <hr />
              <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 15, marginLeft: 15 }}>
                <td class="table-content">
                  {t('Total')}

                </td>
                <td>{data?.[0]?.price ?? 0}</td>
              </div>

              <div class="Address text-center mt-5">
                <p>{t('Thank you for shopping with us!')}</p>
                <div>
                  {/* <strong>{t('1-art-app')}</strong> */}
                  <img
                    src={Logo}
                    class="logo-img"
                  />
                  <p></p>
                  60 rue François 1er, 75008, France
                  {/* <a href="mailto:musamajutt038@gmail.com">musamajutt038@gmail.com</a> */}
                  {/* 1-art.co */}
                </div>
              </div>


            </div>








            {/* <OrderInvoices /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderInvoices