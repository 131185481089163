import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import dayjs from 'dayjs';
import cookies from "js-cookie";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Webcam from "react-webcam";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Buffer } from "buffer";

// Components
import Qrcode from './Qrcode';
import Loader from './Loader/Loader';
import APIService from '../networks/APIService';

// Assets
import Icon from '../assets/img/Website Assets/Icons/Fleche Swap - noir.png'
import TeamIcon from '../assets/img/Website Assets/bath.jpeg'
import ShareIcon from '../assets/img/Website Assets/Icons/Share.png'
import LikeIcon from '../assets/img/Website Assets/Icons/Likes.png'
import LikesPleinIcon from '../assets/img/Website Assets/Icons/Likes_Plein.png'
import WishListIcon from '../assets/img/Website Assets/Icons/wishlist.png'
import WishListPleinIcon from '../assets/img/Website Assets/Icons/wishlist_plein.png'
import DoneIcon from '../assets/img/Website Assets/Icons/done.jpg'
import marinoImage from '../assets/img/Website Assets/GTO Carousel/GTO - Bureau 2.png'
import NewsImage from '../assets/img/Website Assets/GTO Carousel/GTO 1.png'
import GTOImage from '../assets/img/Website Assets/GTO Carousel/GTO 2.png'
import CollectionImage from '../assets/img/Website Assets/Collection Pics/GTO Collection Computer.png'
import GTOFourImage from '../assets/img/Website Assets/GTO Carousel/GTO 4.png'
import FlecheImage from '../assets/img/Website Assets/Icons/Fleche.png'
import BurgerIcon from '../assets/img/Website Assets/Icons/burger-bar.png'
import OrderInvoices from './OrderInvoices';
import Logo from '../assets/img/Website Assets/1-ART Logo/Logo - Point Rouge.png'
import FlecheIcon from '../assets/img/Website Assets/Icons/Fleche.png'
import VideoIcon from '../assets/img/videoIcon.png'

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user",
};

const grid = 8;

const Home = () => {
  const navigate = useNavigate()
  const { nfcNumber } = useParams()
  const webcamRef = useRef(null);
  const webcamRef1 = useRef(null);
  const isUserGift = new URL(window.location.href).searchParams.get('isUserGift')
  const temp = localStorage.getItem('user');
  const auth = JSON.parse(temp);
  const tempUserSerialNumber = localStorage.getItem('userSerialNumber');
  const userSerialNumber = JSON.parse(tempUserSerialNumber);

  const isProductOwner = userSerialNumber && userSerialNumber.includes(nfcNumber)



  const [slideIndex, setSlideIndex] = useState(1);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isQR, setIsQR] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImageUploading, setLoadingImageLoading] = useState(false);
  const [metaInformation, setMetaInformation] = useState([]);
  const [message, setMessage] = useState('');
  const [signature, setSignature] = useState('');
  const [threeDImage, setThreeDImage] = useState('');
  const [otpSuccess, setOtpSuccess] = useState(false)
  const [transactionMessage, setTransactionMessage] = useState('')
  const [transactionFirstName, setTransactionFirstName] = useState('')
  const [transactionLastName, setTransactionLastName] = useState('')
  const [transactionEmail, setTransactionEmail] = useState('')
  const [transactionConfirmEmail, setTransactionConfirmEmail] = useState('')
  const [productTransactionDetail, setProductTransactionDetail] = useState([])
  const [totalEditions, setTotalEditions] = useState(0)
  const [userEdition, setUserEdition] = useState(0)
  const [opt1, setOtp1] = useState('')
  const [updatedUserEdition, setUpdatedUserEdition] = useState(0)
  const [confirmUserEdition, setConfirmUserEdition] = useState(0)
  const [isLike, setIsLike] = useState(false)
  const [isHeart, setIsHeart] = useState(false)
  const [viewCount, setViewCount] = useState(0)
  const [copied, setCopied] = useState(false);
  const [giftDetails, setGiftDetails] = useState({})
  const [invoiceDetail, setInvoiceDetail] = useState({})
  const [invoiceModal, setInvoiceModal] = useState(false)
  const [uploadedImagesUrl, setUploadedImagesUrl] = useState([]);
  const [productImages, setProductImages] = useState([])
  const [selectedDeletedImgUrlForAPI, setSelectedDeletedImgUrlForAPI] = useState('')
  const [reload, setReload] = useState(false)
  const [selectedZoomImage, setSelectedZoomImage] = useState('')

  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [LiveVideoIsVisible, setLiveVideoIsVisible] = useState(false)

  const [showLiveVideo, setShowLiveVideo] = useState(false)
  const [showLiveVideoModal, setShowLiveVideoModal] = useState(false)
  const [isDeletePhotoOpen, setIsDeletePhotoOpen] = useState(false)
  const [selectedDeletedImgUrl, setSelectedDeletedImgUrl] = useState('')
  const [giftFirstName, setGiftFirstName] = useState('')
  const [giftLastName, setGiftLastName] = useState('')
  const [giftEmail, setGiftEmail] = useState('')
  const [giftConfirmEmail, setGiftConfirmEmail] = useState('')
  const [giftMessage, setGiftMessage] = useState('')
  const [giftDiff, setGiftDiff] = useState(false)
  const [dateTime, setDateTime] = useState()
  const [imgSrc, setImgSrc] = useState(null);
  const [fileNames, setFileNames] = useState({
    regular: 'Photos/Vidéos',
    live: 'Live Photos/Vidéos',
    regular1: 'Photos/Vidéos',
    live1: 'Live Photos/Vidéos',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (nfcNumber != undefined) {
      setLoading(true);
      getProduct();
    }
  }, [])

  const getProduct = async () => {
    APIService.getSingleProduct(nfcNumber).then((response) => {
      setProduct(response?.product?.[0] ?? null)
      setMessage(response?.product?.[0]?.message)
      setProductTransactionDetail(response?.productTransaction)
      setProductImages(response?.product?.[0]?.productImages ?? [])
      setGiftDetails(response?.giftDetail)
      setInvoiceDetail(response?.getFullInvoiceData)
      const grouped = response.metaDetail.map((item) => {
        return {
          [item.key]: item.value
        }
      })
      setMetaInformation(grouped)
      setTotalEditions(response?.totalEditions ?? 0)
      setUserEdition(response?.userEditions ?? 0)
      setSignature(response?.metaDetail.filter(_ => _.key === 'artist_signature')[0]?.value ?? '')
      setThreeDImage(response?.metaDetail.filter(_ => _.key === '3d_image')[0]?.value ?? '')
      setLoading(false);
      getUserLikeUnlike(response?.product?.[0]?.productId)
    }
    ).catch((error) => {
      setLoading(false);
    });
  }

  const toggleImage = () => {
    if (threeDImage === "" || threeDImage === null) {
      toast(t('3D image not available'));
    } else {
      setIsOpen(!isOpen);
    }
  };

  const toggleQR = () => {
    setIsQR(!isQR);
  };

  const closeAll = () => {
    setIsOpen(false);
    setIsQR(false);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const plusSlides = (n) => {
    showSlides(slideIndex + n);
  };

  const currentSlide = (n) => {
    showSlides(n);
  };

  const showSlides = (n) => {
    let newIndex = n;
    const slides = document.querySelectorAll(".slides");
    if (slides.length > 0) {
      const dots = document.querySelectorAll(".slide-thumbnail");

      if (n > slides && slides.length) {
        newIndex = 1;
      }
      if (n < 1) {
        newIndex = slides && slides.length;
      }

      setSlideIndex(newIndex);

      slides.forEach((slide) => {
        slide.style.display = "none";
      });
      dots.forEach((dot) => {
        dot.classList.remove("active");
      });

      slides[newIndex - 1].style.display = "block";
    }
    // dots[newIndex - 1].classList.add("active");
  }

  useEffect(() => {
    if (product && product.productImages && product.productImages.length > 0) {
      setTimeout(() => {
        showSlides(1);
      }, 500);
    }
  }, [product]);

  useEffect(() => {
    const items = document.querySelectorAll(".carousel .carousel-item");
    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (let i = 1; i < minPerSlide; i++) {
        if (!next) {
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Adjust the threshold as needed
      const threshold = 670;

      if (documentHeight - scrollPosition - windowHeight < threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const updateProductInformation = () => {
    APIService.customizeProduct(product.id, message, uploadedImagesUrl, nfcNumber).then((response) => {
      window.location.reload()
    }).catch((error) => {
      console.log('error', error)
    });
  }

  const sendOtp = () => {
    setOtpSuccess(false)
    if (!transactionFirstName || !transactionLastName || !transactionEmail) {
      toast(t('Please fill all fields'))
    } else if (
      transactionEmail !== transactionConfirmEmail
    ) {
      toast(t('Email and Confirm Email should be same'))
    } else {
      setLoading(true)
      APIService.transactionOtp(transactionMessage, transactionFirstName, transactionLastName, transactionEmail, auth.email).then((response) => {
        toast(t('Otp sent successfully'))
        setOtpSuccess(true)
        setLoading(false)
      }).catch((error) => {
        setOtpSuccess(false)
        setLoading(false)
        console.log('error', error)
      });
    }
  }

  const verifyTransaction = () => {
    setLoadingImageLoading(true)
    APIService.verifyTransactionOtp(transactionEmail, opt1, auth.email, nfcNumber, transactionMessage, transactionFirstName, transactionLastName, product.id).then((response) => {
      console.log('response', response)
      toast(t('Transaction is successful'))
      setOtp1('')
      navigate('/collection')
      setLoadingImageLoading(false)
    }).catch((error) => {
      toast(t('Transaction is not successful, Invalid OTP'))
      setLoadingImageLoading(false)
      console.log('error', error)
    });
  }

  const verifyTransactionOtp = (isGift) => {
    if (isGift) {
      debugger
      setLoading(true)
      APIService.verifyGiftOtp(giftEmail, opt1, auth.email, nfcNumber, giftMessage, giftFirstName, giftLastName, uploadedImagesUrl, product.id, giftDiff, dateTime).then((response) => {
        toast(t('Transaction is successful'))
        navigate('/collection')
        setOtp1('')
        setLoading(false)
      }).catch((error) => {
        toast(t('Transaction is not successful, Invalid OTP'))
        setLoading(false)
        console.log('error', error)
      });
    } else {
      setLoadingImageLoading(true)
      APIService.verifyTransactionOtp(transactionEmail, opt1, auth.email, nfcNumber, transactionMessage, transactionFirstName, transactionLastName, product.id).then((response) => {
        toast(t('Transaction is successful'))
        navigate('/collection')
        setOtp1('')
        setLoadingImageLoading(false)
      }).catch((error) => {
        toast(t('Transaction is not successful, Invalid OTP'))
        setLoadingImageLoading(false)
        console.log('error', error)
      });
    }
  }

  const handleSubmitUserEdition = (id) => {
    if (updatedUserEdition === 0) {
      toast(t('Please enter the number of editions'))
    } else if (updatedUserEdition > totalEditions) {
      toast(t('Editions should be less than total editions'))
    } else if (updatedUserEdition != confirmUserEdition) {
      toast(t('Editions and confirm editions should be same'))
    }
    else {

      setLoading(true)
      APIService.updateUserEdition(Number(updatedUserEdition), id).then((response) => {
        console.log('response', response)
        window.location.reload()
        toast(t('Edition updated successfully'))
        setLoading(false)
      }).catch((error) => {
        console.log('error', error)
        setLoading(false)
      });
    }
  }

  const postUserLikeUnlike = () => {
    const data = { productId: product?.productId, userId: auth.id }
    APIService.likeUnlike(data).then((response) => {
      console.log('response', response)
      if (response.message === 'Like Successfully') {
        setIsLike(true)
      } else {
        setIsLike(false)
      }
      toast(response.message)
    }
    ).catch((error) => {
      console.log('error', error)
    });
  }

  const postUserHeartUnheart = () => {
    const data = { productId: product?.productId, userId: auth.id }
    APIService.heartUnHeart(data).then((response) => {
      if (response.message === 'Heart Successfully') {
        setIsHeart(true)
      } else {
        setIsHeart(false)
      }
      toast(response.message)
    }
    ).catch((error) => {
      console.log('error', error)
    });
  }

  const getUserLikeUnlike = (productId) => {
    const data = { productId: productId, userId: auth.id }
    APIService.getLikeUnlike(data).then((response) => {
      console.log('response', response)
      setIsLike(response.like && response.like.length > 0 ? true : false)
      setIsHeart(response.heart && response.heart.length > 0 ? true : false)
      setViewCount(response.views)
    }
    ).catch((error) => {
      console.log('error', error)
    });
  }

  const handleCloseInvoiceModal = () => {
    setInvoiceModal(false)
  }


  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef1.current.stream, {
      mimeType: 'video/mp4'
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef1, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks(prev => prev.concat(data));
    }
  }, [setRecordedChunks]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleUploadVideo = () => {
    setLoadingImageLoading(true)
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4'
      });
      const formData = new FormData();
      formData.append('video', blob);

      APIService.uploadVideo(formData).then((response) => {
        toast('Video uploaded successfully.')
        setProductImages([response.upload.secure_url, ...productImages, ]);
        setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
        setRecordedChunks([])
        setLoadingImageLoading(false)
        setLoading(false)
      }).catch((error) => {
        setLoadingImageLoading(false)
        console.log('error', error)
      });
    }
  }

  const captureModal = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, "");

    setLoadingImageLoading(true)
    APIService.uploadBase64Image(imageSrc).then((response) => {
      setProductImages([response.upload.secure_url, ...productImages]);
      setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);

      // setReload(!reload)
      setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl])
      // getProduct()
      setLoadingImageLoading(false)
    }).catch((error) => {
      setUploadedImagesUrl([])
      setLoadingImageLoading(false)
      console.log('error', error)
    });
    setShowLiveVideoModal(false)
    setImgSrc(imageSrc);
  }

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    const base64Data = imageSrc.replace(/^data:image\/\w+;base64,/, "");
    const binaryData = Buffer.from(base64Data, 'base64');


    setLoadingImageLoading(true)
    APIService.uploadBase64Image(imageSrc).then((response) => {
      setProductImages([response.upload.secure_url, ...productImages, ]);
      setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
      setReload(!reload)
      setLoadingImageLoading(false)
    }).catch((error) => {
      setUploadedImagesUrl([])
      setLoadingImageLoading(false)
      console.log('error', error)
    });
    setShowLiveVideo(false)
    setImgSrc(imageSrc);
  }

  const onDrop = React.useCallback((files) => {
    const maxSizeInMB = 100;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; 
    if (files && files.length) {
      setLoadingImageLoading(true)
      const filename = files[0].name;

      console.log('files>>>22',files)
      var parts = filename.split(".");
      if (parts?.[1] === 'mp4' || parts?.[1] === 'webp' || parts?.[1] === 'tipp' || parts?.[1] === 'avi' || parts?.[1] === 'wmv' || parts?.[1] === 'webm' || parts?.[1] === 'flv' || parts?.[1] === 'm4v' || parts?.[1] === 'mov' || parts?.[1] === 'm-jepg') {
        if (+files[0].size > +maxSizeInBytes) {
          setLoadingImageLoading(false)
          toast('File size should be less than 100MB')
        } else {
        const formData = new FormData();
        formData.append('video', files[0]);
        APIService.uploadVideoFile(formData).then((response) => {

          toast('Video uploaded successfully.')
          setProductImages([response.upload.secure_url, ...productImages]);
          setUploadedImagesUrl([response.upload.secure_url, ...uploadedImagesUrl]);
          // setReload(!reload)
          setRecordedChunks([])
          setLiveVideoIsVisible(false)
          setLoadingImageLoading(false)
        }).catch((error) => {
          setLoadingImageLoading(false)
          console.log('error', error)
        });
      }
      } else {
        // setLoading(true)
        APIService.uploadImage(files).then((response) => {
          const temp = []
          response.upload.forEach(_ => {
            temp.push(_.secure_url)
          })
          setUploadedImagesUrl([...temp, ...uploadedImagesUrl])
          setProductImages([...temp, ...productImages])
          setLoadingImageLoading(false)
        }).catch((error) => {
          setLoadingImageLoading(false)
          setUploadedImagesUrl([])
          console.log('error', error)
        });
      }
    }
  }, [productImages]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleFileChange = (event, type) => {
    setLoadingImageLoading(true)
    // const selectedFile = event.target.files[0];
    // if (selectedFile) {
    //   setFileNames(prevState => ({
    //     ...prevState,
    //     [type]: selectedFile.name
    //   }));
    // } else {
    //   setFileNames(prevState => ({
    //     ...prevState,
    //     [type]: type === 'regular' ? 'Photos/Vidéos' : 'Live Photos/Vidéos'
    //   }));
    // }
    APIService.uploadImage(event.target.files).then((response) => {
      toast(t('Image uploaded successfully.'))
      const temp = []
      response.upload.forEach(_ => {
        temp.push(_.secure_url)
      })

      setUploadedImagesUrl([...temp, ...uploadedImagesUrl])
      setProductImages([...temp, ...productImages])
      setUploadedImagesUrl(temp)
      getProduct()
      setLoadingImageLoading(false)
    }).catch((error) => {
      console.log('erroroooroooooooooooooooo1111')
      setUploadedImagesUrl([])
      setLoadingImageLoading(false)
      console.log('error', error)
    });
  };

  const handleDeletePhoto = () => {
    setLoading(true)

    APIService.deleteImage(product.id, selectedDeletedImgUrlForAPI).then((response) => {
      setLoading(false)
      window.location.reload()
      setIsDeletePhotoOpen(false)
      selectedDeletedImgUrl('')
      setSelectedDeletedImgUrlForAPI('')
    }).catch((error) => {
      setLoading(false)
      console.log('error', error)
    });
  }

  const handleDeletePhotoForDelete = () => {
    setLoadingImageLoading(true)

    APIService.deleteImage(product.id, selectedDeletedImgUrlForAPI).then((response) => {
      setLoadingImageLoading(false)
      setTimeout(() => {
        APIService.customizeProduct(product.id, message, uploadedImagesUrl, nfcNumber).then((response) => {
          console.log('response', response)
          window.location.reload()
        }).catch((error) => {
          setLoadingImageLoading(false)
          console.log('error', error)
        });
      }, 2000);

    }).catch((error) => {
      setLoadingImageLoading(true)(false)
      console.log('error', error)
    });
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      { images: productImages },
      result.source.index,
      result.destination.index
    );
    setProductImages(items)
    saveDragedImages(items)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list.images);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const saveDragedImages = (images) => {
    // setLoading(true)
    setLoadingImageLoading(true)
    APIService.saveDragImages(product.id, message, images,).then((response) => {
      setLoadingImageLoading(false)
    }).catch((error) => {
      setLoadingImageLoading(false)
      console.log('error', error)
    });
  }

  const getListStyle = isDraggingOver => ({
    display: 'flex',
    padding: grid,
    overflow: 'auto',
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    ...draggableStyle
  });

  const largePhoto = (url) => {
    setIsDeletePhotoOpen(true)
    setSelectedDeletedImgUrl(url)
  }


  const validateGift = () => {
    if (!giftFirstName || !giftLastName || !giftEmail || !giftConfirmEmail || !giftMessage) {
      toast(t('Please fill all fields'))
    } else if (
      giftEmail !== giftConfirmEmail
    ) {
      toast(t('Email and Confirm Email should be same'))
    } else {
      setLoading(true)
      APIService.sendGift(giftFirstName, giftLastName, giftEmail, giftMessage, giftDiff, uploadedImagesUrl, auth.email).then((response) => {
        toast(t('Otp Send Successfully'))
        setLoading(false)
        setReload(!reload)
      }).catch((error) => {
        setLoading(false)
        console.log('error', error)
      });
    }
  }


  const toggleCheckbox = () => {
    setGiftDiff(!giftDiff)
  }

  const onChangeDateTime = (date) => {
    setDateTime(new Date(date.target.value).toISOString())
  }

  // Component to render AST
  const RenderNode = ({ node }) => {
    if (!node) return null;

    switch (node.type) {
      case 'root':
        return <div>{node?.children?.map((child, index) => <RenderNode key={index} node={child} />)}</div>;

      case 'paragraph':
        return <p>{node?.children.map((child, index) => <RenderNode key={index} node={child} />)}</p>;

      case 'text':
        return <div>{node?.value}</div>;

      default:
        return null;
    }
  };

  const selectTranslation = useMemo(() => {
    const bio = metaInformation
      .filter(item => item.artist_bio)
    return cookies.get("i18next") === "fr" ?
      product?.productName === '250 GTO Floating' ? `${t("GTO Floating")}` :
        product?.productName === '911 Emerging' ? `${t("911 Emerging")}` :
          product?.productName === 'Antichron' ? `${t("Antichron")}` :
            product?.productName === 'Metatron' ? `${t("Metatron")}` :
              product?.productName === 'Mickey Money' ? `${t("Mickey Money")}` :
                product?.productName === 'P4 Emerging' ? `${t("P4 Emerging")}` :
                  product?.productName === 'Type D Floating' ? `${t("D-Type Floating")}` :
                    "en" :
      bio && bio.length > 0 && bio.map(item => item.artist_bio)

  }, [metaInformation, product])

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userSerialNumber');
    window.location.href = 'https://1-art.co/account/login';
  }

  // product?.productName
  const size = product && product?.productName && product?.productName.match(/\(([^)]+)\)/)[1];

  return (
    <React.Fragment>

      <div
        class="modal fade"
        id="exampleModal7"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body">
              <div
                class="d-flex justify-content-center align-items-center"
              >
                <div class="w_544px">
                  <div class="contact-border-personal bg-white py-5">
                    <h3 class="text-center mb-5">{t('Offrir')}</h3>
                    <p class="transaction-para">
                      {t('receivedEmail')}
                      ({auth?.email ?? 'matthew*********1fab.com'} ) {t('emailConfirmation')}
                    </p>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <hr class="w_100 fw-bold" />
                      <p class="transaction-para mx-3 m-0">
                        {t('securityCode')}
                      </p>
                      <hr class="w_100" />
                    </div>
                    <ul
                      class="d-flex justify-content-center align-items-center list-unstyled mb-5"
                    >
                      <OtpInput
                        value={opt1}
                        onChange={setOtp1}
                        numInputs={6}
                        renderSeparator={<span>  -  </span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{ alignSelf: 'center', marginTop: 10 }}
                        inputStyle={{ alignSelf: 'center', width: 40, height: 40, borderRadius: 4 }}
                      />

                    </ul>
                    <button
                      class="btn btn-register d-flex justify-content-center align-items-center w-100"
                      data-bs-dismiss="modal"
                      aria-label='Close'
                      type='button'
                      onClick={(e) => {
                        verifyTransactionOtp(true)
                      }}
                    >
                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                      <img
                        src={FlecheImage}
                        width="60"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body">
              <div class="d-flex justify-content-center align-items-center">
                <div class="w_544px">
                  <div class="contact-border-personal bg-white py-5">
                    <h3 class="text-center mb-5">{t('Transaction')}</h3>

                    <p class="transaction-para">
                      {t('receivedEmail')}
                      ({auth?.email ?? 'matthew*********1fab.com'} ) {t('emailConfirmation')}
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                      <hr class="w_100 fw-bold" />
                      <p class="transaction-para mx-3 m-0">
                        {t('securityCode')}
                      </p>
                      <hr class="w_100" />
                    </div>
                    <ul class="d-flex justify-content-center align-items-center list-unstyled mb-5">
                      <OtpInput
                        value={opt1}
                        onChange={setOtp1}
                        numInputs={6}
                        renderSeparator={<span>  -  </span>}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{ alignSelf: 'center', marginTop: 10 }}
                        inputStyle={{ alignSelf: 'center', width: 40, height: 40, borderRadius: 4 }}
                      />

                    </ul>
                    <button
                      onClick={() => verifyTransaction()}
                      data-bs-dismiss="modal"
                      aria-label='Close'
                      class="btn btn-register d-flex justify-content-center align-items-center w-100" >
                      <p class="lh-sm text-start m-0">{t('confirm')}</p>
                      <img
                        src={FlecheImage}
                        width="60"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="sketchfab-embed-wrapper mt-5">
        {!loading && product ?
          <div class="overflow-hidden">
            <div
              class="d-flex justify-content-center fixed-bottom bg-white hidden_in_desktop"
            >
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <span class="navbar-toggler-icon">
                  <img
                    src={BurgerIcon}
                    width="26"
                    height="25"
                  />
                </span>
              </button>
              <ul class="d-flex justify-content-evenly list-unstyled" style={{ marginTop: 30, marginLeft: 10 }}>
                <h6 class="m-0 cursor_pointer me-3">{t('Suivre 1-ART')}</h6>
                <li class="mx-1"><a href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                <li class="mx-1"><a href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                <li class="mx-1"><a href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                <li class="mx-1"><a href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                <li class="mx-1"><a href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
              </ul>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasTop"
                aria-labelledby="offcanvasTopLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close text-reset m-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">
                  <div class="menu">
                    <ul
                      class="d-flex flex-column align-items-center list-unstyled mt-5"
                    >
                      {window.location.pathname.split('/')[1] != 'collection' &&
                        <li class="mb-5" >
                          <Link to={auth?.id ? "/collection" : "/login"} style={{ textDecoration: "none", color: 'black' }}>
                            <h6
                              class="m-0 cursor_pointer"
                            >
                              {t('myCollection')}
                            </h6>
                          </Link>
                        </li>
                      }
                      <li class="mb-5">
                        <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                          <h6
                            class="m-0 cursor_pointer"
                          >
                            {t('news_on_instragram')}
                          </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                          >
                            <div class="modal-content">
                              <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                              <div class="modal-body">
                                <div class="container text-center my-3">
                                  <div
                                    class="row mx-auto my-auto justify-content-center"
                                  >
                                    <div
                                      id="recipeCarousel m-0 p-0"
                                      class="carousel slide"
                                      data-bs-ride="carousel"
                                    >
                                      <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={NewsImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={CollectionImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                {/* <img
                                                src={teamImage}
                                                class="img-fluid"
                                              /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOFourImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="prevContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="prev"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_left"
                                          />
                                        </a>
                                      </div>
                                      <div class="nextContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="next"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_right"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <h6
                          class="m-0 cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalResponsive1"
                        >
                          {t('information')}
                        </h6>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive1"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered bg-white w_85"
                          >
                            <div class="modal-content">
                              <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                              <div class="modal-body">
                                <ul
                                  class="d-flex flex-column align-items-center list-unstyled"
                                >
                                  <li class="mb-5 cursor_pointer">
                                    <h6 class="m-0 fw-bold cursor_pointer">
                                      {t('information')}
                                    </h6>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                    <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                                      <h6 class="m-0 cursor_pointer">
                                        {t('order_tracing')}
                                      </h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                    <Link className="text-decoration-none text-black" to="/faq">
                                      <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                                    </Link>
                                  </li>

                                  <li class="mb-5">
                                    <Link className="text-decoration-none text-black" to="/privacy-policy">
                                      <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5">
                                    <Link className="text-decoration-none text-black" to="/terms-and-service">
                                      <h6 class="m-0 cursor_pointer">
                                        {t('termOfService')}
                                      </h6>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                          <h6
                            class="m-0 cursor_pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModalResponsive2"
                          >
                            {t('Contact')}
                          </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive2"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div
                                        class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                      >
                                        <h1 class="fw-bold m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red">{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-bold mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div
                                        class="d-flex justify-content-between mb-3"
                                      >
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>

                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('bussiness')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('reasonForRequest')}</label>
                                        <select
                                          class="form-select custom-select w_400 w-100"
                                          id="inputGroupSelect01"
                                        >
                                          <option value="1">{t('customerService')}</option>
                                          <option value="2">
                                            {t('pressCommunication')}
                                          </option>
                                          <option value="3">
                                            {t('partnerShip')}
                                          </option>
                                        </select>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Vente')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"
                                        ></textarea>
                                      </div>
                                      <div
                                        class="d-flex justify-content-between align-items-center mb-2 text-start"
                                      >
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox">
                                            <input type="checkbox" id="checkbox" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                          <h6 class="m-0 cursor_pointer">
                            {t('artist')}
                          </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive3"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div class="transform_img_layer d-flex align-items-center position-absolute bottom-0">
                                        <h1 class="fw-bold m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red"
                                          >{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-bold mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div class="d-flex justify-content-between mb-3">
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Message')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('photos')}</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mb-2 text-start">
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox2">
                                            <input type="checkbox" id="checkbox2" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5" >
                        <div onClick={handleLogout} style={{ textDecoration: "none", color: 'black' }}>
                          <h6
                            class="m-0 cursor_pointer"
                          >
                            {t('logOut')}
                          </h6>
                        </div>
                      </li>
                      {/* <li class="mb-3 d-flex justify-content-evenly list-unstyled">
                        <h6 class="m-0 cursor_pointer" >{t('Suivre 1-ART')}</h6>
                        <li class="mx-1"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-1"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                      </li> */}
                    </ul>
                    {/* <ul class="d-flex justify-content-evenly list-unstyled">
                      <li class="mx-1"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                      <li class="mx-1"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                      <li class="mx-1"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                      <li class="mx-1"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                      <li class="mx-1"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                    </ul> */}
                  </div>
                </div>
                <a href="https://1-art.co/account" class="h5 text-decoration-none" style={{ backgroundColor: '#1d1d1b08', padding: '1rem 2rem' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-account" fill="none" viewBox="0 0 18 19" style={{
                    height: '2rem',
                    width: '2rem',
                    marginRight: '1rem'
                  }}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z" fill="currentColor">
                    </path>
                  </svg>Account
                </a>
              </div>
            </div>

            {isProductOwner === true &&
              <div class="menu-personal-2">
                <ul class="d-flex justify-content-between align-items-center list-unstyled m-0 mx-2">
                  <li class="cursor_pointer">
                    <h6
                      class="m-0 fw-bold cursor_pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal3">
                      {t('Personalize')}
                    </h6>
                    <div
                      class="modal fade"
                      id="exampleModal3"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered modal-xl p-0">
                        <div class="modal-content">
                          <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="modal-body m-0 p-3">
                            <div class="row">
                              <div class="col-md-12 col-lg-4 m_top">
                                <div class="contact-border-personal-responsive bg-white py-5">
                                  <h3 class="text-center mb-4">{t('Personalize')}</h3>
                                  <p class="text-center">
                                    {t('drag')}
                                  </p>
                                  <div class="d-flex flex-wrap mb-5">
                                    {isDeletePhotoOpen ?
                                      <React.Fragment>
                                        <div className='position-relative w-100 mb-3'>
                                          <div style={{ position: 'absolute', left: -10, top: -8, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                            setIsDeletePhotoOpen(false)
                                            setSelectedDeletedImgUrl('')
                                            setSelectedDeletedImgUrlForAPI('')
                                          }}>
                                            <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                          </div>
                                          <div className='delete-image-main'>
                                            <img
                                              src={selectedDeletedImgUrl}
                                              class="w-100 mb-3"
                                            />
                                          </div>
                                        </div>
                                        <div className='col-12'>
                                          <div className='row'>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4'>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='regularFileInputff'
                                              >{fileNames.regular}</label>
                                              <input
                                                {...getInputProps()}
                                                {...getRootProps()}
                                                type="file"
                                                className="custom-file-input"
                                                id="regularFileInputff"
                                                accept="image/*"
                                                capture={false}
                                              // onChange={(event) => handleFileChange(event, "regular")}
                                              />
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='liveFileInput'>
                                                {fileNames.live}
                                              </label>
                                            </div>
                                          </div>
                                        </div>


                                        <div
                                          onClick={() => {
                                            if (uploadedImagesUrl && uploadedImagesUrl.length > 0) {
                                              handleDeletePhotoForDelete()
                                            } else {
                                              handleDeletePhoto()
                                            }
                                          }}
                                          class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                          {loading || loadingImageUploading ? <Loader /> :
                                            <React.Fragment>
                                              <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                              <img
                                                src={FlecheIcon}
                                                width="60"
                                              />
                                            </React.Fragment>
                                          }
                                        </div>
                                        {showLiveVideo &&
                                          <React.Fragment>
                                            <Webcam
                                              audio={false}
                                              height={300}
                                              ref={webcamRef}
                                              screenshotFormat="image/png"
                                              width={300}
                                              videoConstraints={videoConstraints}
                                            />
                                            <div
                                              onClick={() => capture()}
                                              style={{ alignSelf: 'center', marginTop: 10 }}
                                              class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                              {t('Capture photo')}
                                            </div>
                                            <div
                                              onClick={() => {
                                                setLiveVideoIsVisible(true)
                                                setShowLiveVideo(false)
                                              }}
                                              style={{ alignSelf: 'center', marginTop: 10, marginBottom: 10 }}
                                              class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">
                                              Live Video
                                            </div>
                                          </React.Fragment>
                                        }
                                        {LiveVideoIsVisible &&
                                          <React.Fragment>
                                            <Webcam style={{ width: '100%', marginTop: 20 }} audio={true} ref={webcamRef1} videoConstraints={videoConstraints} />
                                            {capturing ? (
                                              <div
                                                onClick={handleStopCaptureClick}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                Stop Capture
                                              </div>
                                            ) : (
                                              <div
                                                onClick={handleStartCaptureClick}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                Start Capture
                                              </div>
                                            )}
                                            {recordedChunks.length > 0 && (
                                              <div
                                                onClick={handleUploadVideo}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                Upload Video
                                              </div>
                                            )}
                                          </React.Fragment>
                                        }
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                          <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided, snapshot) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                              >

                                                {productImages && productImages.length > 0 && productImages.map((item, index) => {
                                                  const fileExtension = item.substring(item.lastIndexOf('.') + 1);
                                                  return (
                                                    <Draggable key={JSON.stringify(item)} draggableId={JSON.stringify(item)} index={index}>
                                                      {(provided, snapshot) => (
                                                        <div
                                                          className='position-relative  product-close-main'
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                          )}
                                                        >
                                                          <div className='product-close' style={{ position: 'absolute', right: 20, top: 12, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                                            largePhoto(fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item)
                                                            setSelectedDeletedImgUrlForAPI(item)
                                                          }
                                                          }>
                                                            <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                                          </div>
                                                          <img
                                                            src={fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item}
                                                            class="personal-add-item-img me-md-2 me-4 mb-3"
                                                            alt=""
                                                          />
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                })}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                        <div className='container-fluid'>
                                          <div className='row mt-5'>
                                            <div className='col-12'>
                                              <div class="position-relative mb-4">
                                                <label
                                                  for="formGroupExampleInput"
                                                  class="form-label bg-white start_15 position-absolute px-3"
                                                >{t('Message')}</label>
                                                <textarea
                                                  type="text"
                                                  rows="3"
                                                  value={message}
                                                  onChange={(e) => setMessage(e.target.value)}
                                                  class="form-control w_400_area w-100"
                                                  id="formGroupExampleInput" />
                                              </div>
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4'>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='regularFileInput444'
                                              >{fileNames.regular}</label>
                                              <input
                                                {...getInputProps()}
                                                {...getRootProps()}
                                                type="file"
                                                className="custom-file-input"
                                                id="regularFileInput444"
                                                accept="image/*"
                                                capture={false}
                                              // onChange={(event) => handleFileChange(event, "regular")}
                                              />
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4' onClick={() => {
                                              if (LiveVideoIsVisible || showLiveVideo) {
                                                setShowLiveVideo(false)
                                                setLiveVideoIsVisible(false)
                                              } else {
                                                setShowLiveVideo(true)
                                              }
                                            }}>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                style={{ textAlign: 'center' }}
                                                htmlFor='liveFileInput'>
                                                {fileNames.live}
                                              </label>
                                            </div>
                                            {showLiveVideo &&
                                              <React.Fragment>
                                                <Webcam
                                                  audio={false}
                                                  height={300}
                                                  ref={webcamRef}
                                                  screenshotFormat="image/png"
                                                  width={300}
                                                  videoConstraints={videoConstraints}
                                                />
                                                <div
                                                  onClick={() => capture()}
                                                  style={{ alignSelf: 'center', marginTop: 10 }}
                                                  class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                  {t('Capture photo')}
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    setLiveVideoIsVisible(true)
                                                    setShowLiveVideo(false)
                                                  }}
                                                  style={{ alignSelf: 'center', marginTop: 10, marginBottom: 10 }}
                                                  class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">
                                                  Live Video
                                                </div>
                                              </React.Fragment>
                                            }
                                            {LiveVideoIsVisible &&
                                              <React.Fragment>
                                                <Webcam audio={true} ref={webcamRef1} />
                                                {capturing ? (
                                                  <div
                                                    onClick={handleStopCaptureClick}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Stop Capture
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={handleStartCaptureClick}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Start Capture
                                                  </div>
                                                )}
                                                {recordedChunks.length > 0 && (
                                                  <div
                                                    onClick={handleUploadVideo}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Upload Video
                                                  </div>
                                                )}
                                              </React.Fragment>
                                            }
                                          </div>
                                        </div>
                                        <div
                                          onClick={() => updateProductInformation()}
                                          class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                          {loading || loadingImageUploading ? <Loader /> :
                                            <React.Fragment>
                                              <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                              <img
                                                src={FlecheIcon}
                                                width="60"
                                              />
                                            </React.Fragment>
                                          }
                                        </div>
                                      </React.Fragment>
                                    }


                                  </div>
                                  {/* <div class="position-relative mb-4">
                                  <label
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('Message')}*</label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    class="form-control w_400_area w-100"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    id="formGroupExampleInput"></textarea>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                  <div class="custom-file">
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      for="customFile">{t('photo')}</label>
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="customFile"
                                      accept="image/*, .pdf, .doc, .docx"
                                    />
                                  </div>
                                  <div class="custom-file_2">
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      for="customFile">{t('livePhoto')}</label>
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="customFile"
                                      accept="image/*, .pdf, .doc, .docx"
                                    />
                                  </div>
                                </div> */}
                                  {/* <div
                                  onClick={() => updateProductInformation()}
                                  class="btn btn-register-personal d-flex justify-content-center align-items-center w-100"
                                >
                                  <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                  <img
                                    src={FlecheImage}
                                    width="60"
                                  />
                                </div> */}
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-8 mb-5 m_top">
                                <div class="personal-contact-img-personal position-relative bg-white max-h-100">
                                  <div class="personal-content d-flex align-items-center mb-3 p-3">
                                    <img
                                      src={marinoImage}
                                      alt=""
                                      class="me-2"
                                    />
                                    <div class="author-name">
                                      <h6 class="m-0">{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</h6>
                                    </div>
                                  </div>
                                  <img
                                    src={marinoImage}
                                    class="methew-contact-img-border"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div
                      class="modal fade"
                      id="mobileImageShower"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered modal-xl p-0">
                        <div class="modal-content">
                          <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="modal-body m-0 p-3">
                            <div class="row">
                              <div class="col-md-12 col-lg-4 m_top">
                                <div class="contact-border-personal-responsive bg-white py-5">
                                  <h3 class="text-center mb-4">{t('Personalize')}</h3>
                                  <p class="text-center">
                                    {t('drag')}
                                  </p>
                                  <div class="d-flex flex-wrap mb-5">
                                    {isDeletePhotoOpen ?
                                      <React.Fragment>
                                        <div className='position-relative w-100 mb-3'>
                                          <div style={{ position: 'absolute', left: -10, top: -8, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                            setIsDeletePhotoOpen(false)
                                            setSelectedDeletedImgUrl('')
                                            setSelectedDeletedImgUrlForAPI('')
                                          }}>
                                            <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                          </div>
                                          <div className='delete-image-main'>
                                            <img
                                              src={selectedDeletedImgUrl}
                                              class="w-100 mb-3"
                                            />
                                          </div>
                                        </div>
                                        <div className='col-12'>
                                          <div className='row'>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4'>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='regularFileInputff'
                                              >{fileNames.regular}</label>
                                              <input
                                                {...getInputProps()}
                                                {...getRootProps()}
                                                type="file"
                                                className="custom-file-input"
                                                id="regularFileInputff"
                                                accept="image/*"
                                                capture={false}
                                              // onChange={(event) => handleFileChange(event, "regular")}
                                              />
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4' onClick={() => setShowLiveVideo(true)}>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='liveFileInput'>
                                                {fileNames.live}
                                              </label>
                                            </div>
                                          </div>
                                        </div>


                                        <button
                                          data-bs-toggle={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "modal"}
                                          data-bs-target={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "#exampleModal7"}
                                          class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          disabled={loading || loadingImageUploading}
                                          onClick={() => validateGift()}
                                        >
                                          {loading || loadingImageUploading ? <Loader /> :
                                            <>
                                              <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                              <img
                                                src={FlecheIcon}
                                                width="60"
                                              />
                                            </>
                                          }
                                        </button>
                                      </React.Fragment>
                                      :
                                      <React.Fragment>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                          <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided, snapshot) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                              >

                                                {productImages && productImages.length > 0 && productImages.map((item, index) => {
                                                  const fileExtension = item.substring(item.lastIndexOf('.') + 1);
                                                  return (
                                                    <Draggable key={JSON.stringify(item)} draggableId={JSON.stringify(item)} index={index}>
                                                      {(provided, snapshot) => (
                                                        <div
                                                          className='position-relative  product-close-main'
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                          )}
                                                        >
                                                          <div className='product-close' style={{ position: 'absolute', right: 20, top: 12, width: 25, height: 25, textAlign: 'center', backgroundColor: '#fff', marginRight: 0, borderRadius: 10, justifyContent: 'center', alignItems: 'center', }} onClick={() => {
                                                            largePhoto(fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item)
                                                            setSelectedDeletedImgUrlForAPI(item)
                                                          }
                                                          }>
                                                            <i class="fa-solid fa-xmark cursor_pointer" style={{ fontSize: 15, }} aria-label="Close"></i>
                                                          </div>
                                                          <img
                                                            src={fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi' ? VideoIcon : item}
                                                            class="personal-add-item-img me-md-2 me-4 mb-3"
                                                            alt=""
                                                          />
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                })}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                        <div className='container-fluid'>
                                          <div className='row mt-5'>
                                            <div className='col-12'>
                                              <div class="position-relative mb-4">
                                                <label
                                                  for="formGroupExampleInput"
                                                  class="form-label bg-white start_15 position-absolute px-3"
                                                >{t('Message')}</label>
                                                <textarea
                                                  type="text"
                                                  rows="3"
                                                  value={message}
                                                  onChange={(e) => setMessage(e.target.value)}
                                                  class="form-control w_400_area w-100"
                                                  id="formGroupExampleInput" />
                                              </div>
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4'>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                htmlFor='regularFileInput444'
                                              >{fileNames.regular}</label>
                                              <input
                                                {...getInputProps()}
                                                {...getRootProps()}
                                                type="file"
                                                className="custom-file-input"
                                                id="regularFileInput444"
                                                accept="image/*"
                                                capture={false}
                                              // onChange={(event) => handleFileChange(event, "regular")}
                                              />
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12 mb-4' onClick={() => {
                                              if (LiveVideoIsVisible || showLiveVideo) {
                                                setShowLiveVideo(false)
                                                setLiveVideoIsVisible(false)
                                              } else {
                                                setShowLiveVideo(true)
                                              }
                                            }}>
                                              <label
                                                class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                                style={{ textAlign: 'center' }}
                                                htmlFor='liveFileInput'>
                                                {fileNames.live}
                                              </label>
                                            </div>
                                            {showLiveVideo &&
                                              <React.Fragment>
                                                <Webcam
                                                  audio={false}
                                                  height={300}
                                                  ref={webcamRef}
                                                  screenshotFormat="image/png"
                                                  width={300}
                                                  videoConstraints={videoConstraints}
                                                />
                                                <div
                                                  onClick={() => capture()}
                                                  style={{ alignSelf: 'center', marginTop: 10 }}
                                                  class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                  {t('Capture photo')}
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    setLiveVideoIsVisible(true)
                                                    setShowLiveVideo(false)
                                                  }}
                                                  style={{ alignSelf: 'center', marginTop: 10, marginBottom: 10 }}
                                                  class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">
                                                  Live Video
                                                </div>
                                              </React.Fragment>
                                            }
                                            {LiveVideoIsVisible &&
                                              <React.Fragment>
                                                <Webcam audio={true} ref={webcamRef1} />
                                                {capturing ? (
                                                  <div
                                                    onClick={handleStopCaptureClick}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Stop Capture
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={handleStartCaptureClick}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Start Capture
                                                  </div>
                                                )}
                                                {recordedChunks.length > 0 && (
                                                  <div
                                                    onClick={handleUploadVideo}
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                    class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                                    Upload Video
                                                  </div>
                                                )}
                                              </React.Fragment>
                                            }
                                          </div>
                                        </div>
                                        <button
                                          data-bs-toggle={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "modal"}
                                          data-bs-target={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "#exampleModal7"}
                                          class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          disabled={loading || loadingImageUploading}
                                          onClick={() => validateGift()}
                                        >
                                          {loading || loadingImageUploading ? <Loader /> :
                                            <>
                                              <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                              <img
                                                src={FlecheIcon}
                                                width="60"
                                              />
                                            </>
                                          }
                                        </button>
                                      </React.Fragment>
                                    }


                                  </div>
                                  {/* <div class="position-relative mb-4">
                                  <label
                                    for="formGroupExampleInput"
                                    class="form-label bg-white start_15 position-absolute px-3"
                                  >{t('Message')}*</label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    class="form-control w_400_area w-100"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    id="formGroupExampleInput"></textarea>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                  <div class="custom-file">
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      for="customFile">{t('photo')}</label>
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="customFile"
                                      accept="image/*, .pdf, .doc, .docx"
                                    />
                                  </div>
                                  <div class="custom-file_2">
                                    <label
                                      class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                      for="customFile">{t('livePhoto')}</label>
                                    <input
                                      type="file"
                                      class="custom-file-input"
                                      id="customFile"
                                      accept="image/*, .pdf, .doc, .docx"
                                    />
                                  </div>
                                </div> */}
                                  {/* <div
                                  onClick={() => updateProductInformation()}
                                  class="btn btn-register-personal d-flex justify-content-center align-items-center w-100"
                                >
                                  <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                  <img
                                    src={FlecheImage}
                                    width="60"
                                  />
                                </div> */}
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-8 mb-5 m_top">
                                <div class="personal-contact-img-personal position-relative bg-white max-h-100">
                                  <div class="personal-content d-flex align-items-center mb-3 p-3">
                                    <img
                                      src={marinoImage}
                                      alt=""
                                      class="me-2"
                                    />
                                    <div class="author-name">
                                      <h6 class="m-0">{auth?.firstName + ' ' + auth?.lastName ?? 'Matthew Tippetts'}</h6>
                                    </div>
                                  </div>
                                  <img
                                    src={marinoImage}
                                    class="methew-contact-img-border"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="mx-2 cursor_pointer">
                    <h6
                      class="m-0 fw-bold cursor_pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal4"
                    >
                      {t('Transaction')}
                    </h6>
                    <div
                      class="modal fade"
                      id="exampleModal4"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="modal-body">
                            <div class="d-flex justify-content-center align-items-center">
                              <div class="w_544px">
                                <div class="contact-border-personal bg-white py-5">
                                  <h3 class="text-center mb-5">{t('Transaction')}</h3>
                                  <div class="position-relative mb-3 mt-5">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15 position-absolute px-3">{t('Type')}</label>
                                    <select
                                      class="form-select custom-select w_400 w-100"
                                      id="inputGroupSelect01"
                                      // onChange={(e) => setTransactionMessage(e.target.value)}
                                      onChange={(e) => {
                                        if (e.target.value === "Lost" || e.target.value === "Stolen") {
                                          setTransactionMessage(e.target.value)
                                          setTransactionFirstName(auth?.firstName)
                                          setTransactionLastName(auth?.lastName)
                                          setTransactionEmail(auth?.email)
                                          setTransactionConfirmEmail(auth?.email)
                                        } else {
                                          setTransactionMessage(e.target.value)
                                          setTransactionFirstName("")
                                          setTransactionLastName("")
                                          setTransactionEmail("")
                                          setTransactionConfirmEmail("")
                                          // setTransactionMessage(e.target.value)
                                        }
                                      }}
                                    >
                                      <option selected></option>
                                      <option value="Lost">{t('Lost')}</option>
                                      <option value="Sale">{t('Sold')}</option>
                                      <option value="Stolen">{t('Stolen')}</option>
                                      <option value="Found">{t('Found')}</option>
                                      <option value="Transfer">{t('Transfer')}</option>
                                    </select>
                                  </div>
                                  {transactionMessage === 'Lost' || transactionMessage === 'Stolen' || transactionMessage === 'Found' ?
                                    null :
                                    <>
                                      <div class="d-flex justify-content-between mb-3">
                                        <div class="position-relative me-3">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3">{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h w-100"
                                            id="formGroupExampleInput"
                                            value={transactionFirstName}
                                            onChange={(e) => setTransactionFirstName(e.target.value)}
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3">{t('lastName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174 w-100"
                                            id="formGroupExampleInput"
                                            value={transactionLastName}
                                            onChange={(e) => setTransactionLastName(e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3">{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                          value={transactionEmail}
                                          onChange={(e) => setTransactionEmail(e.target.value)}
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3">{t('Confirmation')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                          value={transactionConfirmEmail}
                                          onChange={(e) => setTransactionConfirmEmail(e.target.value)}
                                        />
                                      </div>
                                    </>
                                  }
                                  <button
                                    data-bs-toggle={transactionFirstName != "" && transactionLastName != "" && transactionEmail != "" && transactionMessage != "" && "modal"}
                                    data-bs-target={transactionFirstName != "" && transactionLastName != "" && transactionEmail != "" && transactionMessage != "" && "#exampleModal5"}
                                    class="btn btn-register d-flex justify-content-center align-items-center w-100" onClick={() => sendOtp()}>
                                    <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                    <img
                                      src={FlecheImage}
                                      width="60"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="cursor_pointer">
                    <h6
                      class="m-0 fw-bold cursor_pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal6"
                    >
                      {t('Offrir')}
                    </h6>
                    <div
                      class="modal fade"
                      id="exampleModal6"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >

                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="modal-body">
                            <div class="d-flex justify-content-center align-items-center">
                              <div class="w_544px">
                                <div class="contact-border-personal bg-white py-5">
                                  <h3 class="text-center mb-4">{t('Offrir')}</h3>

                                  <div class="d-flex justify-content-between flex-wrap">
                                    <div class="position-relative mb-3 w-100">
                                      <label
                                        for="formGroupExampleInput"
                                        class="form-label bg-white start_15 position-absolute px-3">{t('firstName')}</label>
                                      <input
                                        type="text"
                                        class="form-control w_184_h w-100"
                                        id="formGroupExampleInput"
                                        value={giftFirstName}
                                        onChange={(e) => setGiftFirstName(e.target.value)}
                                      />
                                    </div>
                                    <div class="position-relative mb-3 w-100">
                                      <label
                                        for="formGroupExampleInput"
                                        class="form-label bg-white start_15_contact position-absolute px-3">{t('lastName')}</label>
                                      <input
                                        type="text"
                                        class="form-control w_174 w-100"
                                        id="formGroupExampleInput"
                                        value={giftLastName}
                                        onChange={(e) => setGiftLastName(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div class="position-relative mb-3">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15 position-absolute px-3">{t('E-mail')}</label>
                                    <input
                                      type="text"
                                      class="form-control w_400 w-100"
                                      id="formGroupExampleInput"
                                      value={giftEmail}
                                      onChange={(e) => setGiftEmail(e.target.value)}
                                    />
                                  </div>
                                  <div class="position-relative mb-3">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15 position-absolute px-3">{t('Confirmation')}</label>
                                    <input
                                      type="text"
                                      class="form-control w_400 w-100"
                                      id="formGroupExampleInput"
                                      value={giftConfirmEmail}
                                      onChange={(e) => setGiftConfirmEmail(e.target.value)}
                                    />
                                  </div>
                                  <div class="position-relative mb-2">
                                    <label
                                      for="formGroupExampleInput"
                                      class="form-label bg-white start_15 position-absolute px-3">{t('Message')}*</label>
                                    <textarea
                                      type="text"
                                      rows="3"
                                      class="form-control w_400_area w-100"
                                      id="formGroupExampleInput"
                                      value={giftMessage}
                                      onChange={(e) => setGiftMessage(e.target.value)}
                                    ></textarea>
                                  </div>


                                  <div class="differ-border d-flex justify-content-between align-items-center mb-2 w-100">
                                    {giftDiff ? <input type="datetime-local" style={{ border: 0, width: "81%", marginRight: 8 }} onChange={onChangeDateTime} /> :
                                      <h5 class="m-0">{t('Schedule Timing')}</h5>
                                    }
                                    <label class="switch">
                                      <input type="checkbox" onClick={() => toggleCheckbox()} />
                                      <span class="slider round"></span>
                                    </label>

                                  </div>
                                  <div class="d-flex justify-content-between align-items-center mb-4">
                                    <div class="custom-file" data-bs-toggle="modal" data-bs-target="#mobileImageShower">
                                      <label
                                        class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                        htmlFor="regularFileInput111"
                                      >{t('photo')}</label>
                                      <input
                                        {...getInputProps()}
                                        {...getRootProps()}
                                        type="file"
                                        class="custom-file-input"
                                        id="regularFileInput111"
                                        accept="image/*"
                                        capture={false}
                                      // onChange={(event) => handleFileChange(event, "regular1")}
                                      />
                                    </div>
                                    <div class="custom-file_2" onClick={() => {
                                      if (LiveVideoIsVisible || showLiveVideo) {
                                        setLiveVideoIsVisible(false)
                                      } else {
                                        setLiveVideoIsVisible(true)
                                      }
                                    }}>
                                      <label
                                        class="custom-file-label d-flex justify-content-center align-items-center w-100"
                                        htmlFor="liveFileInput1"
                                      >{t('livePhoto')}</label>
                                    </div>
                                  </div>
                                  {LiveVideoIsVisible &&
                                    <React.Fragment>
                                      <Webcam style={{ width: '100%', marginTop: 20 }} audio={true} ref={webcamRef1} videoConstraints={videoConstraints} />
                                      {capturing ? (
                                        <div
                                          onClick={handleStopCaptureClick}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                          Stop Capture
                                        </div>
                                      ) : (
                                        <div
                                          onClick={handleStartCaptureClick}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                          Start Capture
                                        </div>
                                      )}
                                      {recordedChunks.length > 0 && (
                                        <div
                                          onClick={handleUploadVideo}
                                          style={{ marginTop: 10, marginBottom: 10 }}
                                          class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-100">

                                          Upload Video
                                        </div>
                                      )}
                                    </React.Fragment>
                                  }
                                  {showLiveVideoModal &&
                                    <React.Fragment>
                                      <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        screenshotFormat="image/png"
                                        width={250}
                                        videoConstraints={videoConstraints}
                                      />
                                      <div
                                        onClick={() => captureModal()}
                                        style={{ alignSelf: 'center', marginBottom: 20, marginTop: 10 }}
                                        class="btn btn-register d-flex align-self-center justify-content-center align-items-center w-60">

                                        {t('Capture photo')}
                                      </div>
                                    </React.Fragment>
                                  }
                                  <div class="position-relative"></div>
                                  <button
                                    data-bs-toggle={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "modal"}
                                    data-bs-target={giftMessage && giftConfirmEmail && giftEmail && giftLastName && giftFirstName && "#exampleModal7"}
                                    class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                    disabled={loading || loadingImageUploading}
                                    onClick={() => validateGift()}
                                  >
                                    {loading || loadingImageUploading ? <Loader /> :
                                      <>
                                        <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                        <img
                                          src={FlecheIcon}
                                          width="60"
                                        />
                                      </>
                                    }
                                  </button>
                                  {/* <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal7"
                                  class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                >
                                  <p class="lh-sm text-start m-0">{t('confirm')}</p>
                                  <img
                                    src={FlecheImage}
                                    width="60"
                                  />
                                </button> */}
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            }

            <div class="m-5 margin_remove_in_mobile">
              <div class="row margin_remove_in_mobile">
                <div class="col-md-12 col-lg-8 padding_remove_in_mobile mb-sm-3">
                  <div style={isUserGift === 'true' || message != "" ? { padding: 23, border: '1px solid #707070', borderRadius: 39, } : {}}>
                    {isUserGift === 'true' || message != "" ?
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                          <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ background: '#EFEFEF', width: 50, height: 50, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                              <span>{giftDetails && giftDetails?.firstName ? giftDetails?.firstName.charAt(0).toUpperCase() : auth?.firstName.charAt(0).toUpperCase()} {' '} {giftDetails && giftDetails?.lastName ? giftDetails?.lastName.charAt(0).toUpperCase() : auth?.lastName.charAt(0).toUpperCase()}</span>
                            </div>
                            <div style={{ alignSelf: 'center', marginLeft: 10, fontSize: 14, fontWeight: 'bold' }}>{giftDetails?.firstName ?? auth?.firstName}{' '}{giftDetails?.lastName ?? auth?.lastName}</div>
                          </div>
                          <div style={{ alignSelf: 'center', fontSize: 12 }}>{giftDetails && giftDetails?.createdAt && dayjs(giftDetails.createdAt).format('DD MMM YYYY')}</div>
                        </div>

                        <div style={{ marginLeft: 60, fontSize: 12, marginBottom: 20 }}>{giftDetails?.message ?? message}</div>
                      </div> : null
                    }
                    <div class="position-relative showHoverItems" onMouseEnter={handleHover}
                      onMouseLeave={handleHover}>
                      <div class="margin_remove_in_mobile">
                        <div
                          class='exit position-absolute rounded-pill isHovered text-center'
                        >
                          <i class="fa-solid fa-xmark" style={{ verticalAlign: '-webkit-baseline-middle', display: 'inline-block' }} onClick={() => setIsOpen(closeAll)}></i>
                        </div>
                        {!isQR &&
                          <div
                            class={`three_d ${isOpen && "three_d_condition"} position-absolute text-center isHovered`}
                          >
                            {
                              !isOpen &&
                              <p class="m-0 fw-bold fs-4" onClick={toggleImage}>{"3D"}</p>
                            }
                            {/* {
                              isOpen && !isQR ?
                                <p class="m-0 fw-bold fs-5" onClick={() => setIsQR(toggleQR)}>{'Réalité Augmentée'}</p>
                                : ""
                            } */}
                          </div>
                        }
                        {isOpen && (
                          <div className="open-img position-absolute">
                            {/* <span className="close" onClick={toggleImage}>&times;</span> */}
                            <iframe
                              title="Porsche 911 RS 2.7 Emerging"
                              frameBorder="0"
                              allowFullScreen
                              mozallowfullscreen="true"
                              webkitallowfullscreen="true"
                              allow="autoplay; fullscreen; xr-spatial-tracking"
                              src={threeDImage}
                              style={{ width: '100%', height: '100%', paddingTop: 0 }}
                            ></iframe>
                          </div>
                        )}
                        {isQR && (
                          <div className="qr position-absolute d-flex justify-content-center align-items-center">
                            <div className='hidden_in_desktop flex-column'>
                              <h6 className='fw-bold text-center mb-5 '>{t('augmentedReality')}</h6>
                              <Webcam
                                audio={false}
                                height={"auto"}
                                ref={webcamRef}
                                screenshotFormat="image/png"
                                width={"100%"}
                              />
                            </div>
                            <div className='w-50 p-4 hidden_in_mobile'>
                              <h6 className='fw-bold text-center mb-5'>{t('augmentedReality')}</h6>
                              <div className="">
                                <Qrcode />
                              </div>
                            </div>
                          </div>
                        )}

                        <div class="holder" style={{ borderRadius: 10, backgroundColor: '#1B1B1D' }}>
                          {product && product.productImages && product.productImages.length > 0 && product.productImages.map((image, index) => {
                            const fileExtension = image.substring(image.lastIndexOf('.') + 1);
                            if (fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi') {
                              return (
                                <div class="slides text-center">
                                  <div className="video-main">
                                    <video autoPlay={true} muted controls className='w-100'>
                                      <source src={image} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div class="slides text-center">
                                  <img
                                    src={image}
                                    alt=""
                                    style={{ width: 'inherit!important' }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#zoomImageModal"
                                    onClick={() => setSelectedZoomImage(image)}
                                  />
                                </div>
                              )
                            }
                          })}
                        </div>

                        <div
                          className="modal fade"
                          id="zoomImageModal"
                          tabIndex={-1}
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                            <div className="modal-content" data-bs-dismiss="modal" onClick={() => setSelectedZoomImage("")}>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ position: 'absolute', top: 10, right: 20 }}
                                onClick={() => setSelectedZoomImage("")}
                              />
                              {selectedZoomImage != "" &&
                                <img
                                  src={selectedZoomImage}
                                  alt=""
                                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                />}
                            </div>
                          </div>
                        </div>

                        <div class="prevContainer isHovered">
                          <a class="prev d-flex justify-content-center align-items-center h-100" onClick={() => plusSlides(-1)}>
                            <img
                              src={Icon}
                              class="w_swap_left"
                            />
                          </a>
                        </div>
                        <div class="nextContainer isHovered">
                          <a
                            class="next d-flex justify-content-center align-items-center h-100"
                            onClick={() => {
                              if (product.productImages.length === slideIndex) {

                              } else {
                                plusSlides(1)
                              }
                            }}
                          >
                            <img
                              src={Icon}
                              class="w_swap_right"
                            />
                          </a>
                        </div>
                      </div>
                      <div className='isHovered'>
                        <div
                          class="d-flex flex-nowrap thumnail-section position-absolute"
                        >
                          {product && product.productImages && product.productImages.length > 0 && product.productImages.map((image, index) => {
                            const fileExtension = image.substring(image.lastIndexOf('.') + 1);
                            if (fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'webm' || fileExtension === 'avi') {
                              return (
                                <video autoPlay={true} class="slide-thumbnail mx-4 mb-3" onClick={() => currentSlide(index + 1)}>
                                  <source src={image} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              )
                            } else {
                              return (
                                <img
                                  class="slide-thumbnail mx-4 mb-3"
                                  src={image}
                                  onClick={() => currentSlide(index + 1)}
                                  alt="Caption One"
                                />
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-lg-4">
                  <div class="padd_in_mobile">
                    <div class="d-flex flex-column">
                      <div class="order-2 order-sm-1 certificate pt-3 pb-2">
                        <h4 class="text-center mb-4">{t('Certificat d’Authenticité')}</h4>
                        <div class="d-flex justify-content-between">
                          <div class="lube-content w-50 text-center">
                            <h6>{product?.productName}</h6>
                            <p class="lh-sm m-0">Size: {size === '145mm' || size === '150mm' || size === '112mm' || size === '130mm' ? metaInformation && metaInformation
                              .filter(item => item.product_size || item.product_size_small)
                              .map(item => item.product_size || item.product_size_small) :
                              metaInformation && metaInformation
                                .filter(item => item.product_size_large)
                                .map(item => item.product_size_large)}</p>
                            <p class="lh-sm m-0">
                              {t('creator')}: {metaInformation && metaInformation
                                .filter(item => item.artist_name)
                                .map(item => item.artist_name)}
                            </p>
                            <p class="lh-sm m-0" >{t('Produced on')} {' '} {product && product.fabricationDate && dayjs(product.fabricationDate).format('MMM DD, YYYY')} </p>
                            {isProductOwner && <p class="lh-sm m-0" >{t('owner')}: {auth?.firstName} {auth?.lastName} </p>}
                            <p class="lh-sm m-0" >{t('Limited Series')}: {' '}{new Intl.NumberFormat('en-US').format(totalEditions)}</p>
                            <p class="text_color_red cursor_pointer"
                              data-bs-toggle="modal" data-bs-target="#exampleModalProvence">{t('Provenance')}</p>

                            <div class="modal fade" id="exampleModalProvence" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content" style={{ borderRadius: "25px" }}>
                                  <div class="exit position-absolute d-flex justify-content-center align-items-center" style={{ borderRadius: "50px" }}>
                                    <i class="fa-solid fa-xmark cursor_pointer" data-bs-dismiss="modal" aria-label="Close"></i>
                                  </div>
                                  <div class="modal-body p-4">
                                    <h6 className='fw-bold text-danger'>{t('Provenance')}</h6>
                                    <div className='table_shadow p-3 provenance-height-responsive overflow-auto'>
                                      <table className='mt-4 text-start w-100' style={{ minWidth: 600 }}>
                                        <tr>
                                          <td style={{ fontWeight: 'bold' }}>{t('Date')}</td>
                                          <td style={{ fontWeight: 'bold' }}>{t('Transaction')}</td>
                                          <td style={{ fontWeight: 'bold' }}>{t('owner')}</td>
                                        </tr>
                                        {productTransactionDetail && productTransactionDetail.length > 0 ? productTransactionDetail.map((_, i) => {
                                          return (
                                            <tr>
                                              <td>{dayjs(_?.createdAt).format('DD/MM/YYYY')}</td>
                                              <td>{_?.status}</td>
                                              <td>{_?.firstName + ' ' + _?.lastName ?? ''}</td>
                                              {i === 0 &&
                                                <td className='text-end'>
                                                  <button class="btn btn-Acheter m-0 px-3 py-0" onClick={() => setInvoiceModal(true)}>
                                                    {t('bill')}
                                                  </button>
                                                </td>
                                              }
                                              {/* } */}
                                            </tr>
                                          )
                                        }) : <div style={{ alignSelf: 'center' }}>{t('No Transaction Detail found')}</div>}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p style={{ margin: 0 }}>{t('Edition')} {userEdition} / {new Intl.NumberFormat('en-US').format(totalEditions)}</p>
                            <p class="text_color_red cursor_pointer fw-bold"
                              data-bs-toggle="modal" data-bs-target="#exampleModalPersonnlise">{t('Personalize')}</p>
                            <div class="modal fade" id="exampleModalPersonnlise" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content" style={{ borderRadius: "25px" }}>
                                  <div class="exit position-absolute d-flex justify-content-center align-items-center" style={{ borderRadius: "50px" }}>
                                    <i class="fa-solid fa-xmark cursor_pointer" data-bs-dismiss="modal" aria-label="Close"></i>
                                  </div>
                                  <div class="modal-body p-4">
                                    <h6 className='fw-bold'>{t('Personalize')}</h6>
                                    <div className='p-3'>
                                      <table className='mt-4 text-start w-100'>


                                        <div className='d-flex flex-column flex-sm-row justify-content-between' style={{ marginTop: 20 }}>
                                          <div class="position-relative hide-disable-bg mb-4 me-0 me-sm-3">
                                            <label
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Actual Edition')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_184_h w-100 mr-2"
                                              disabled
                                              value={userEdition}
                                            />
                                          </div>

                                          <div class="position-relative hide-disable-bg">
                                            <label
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Maximim Edition')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_184_h w-100"
                                              disabled
                                              value={new Intl.NumberFormat('en-US').format(totalEditions)}
                                            />
                                          </div>
                                        </div>

                                        <hr style={{ width: '50%', border: '0.5px solid #1d1d1b', margin: '25px auto', opacity: 1 }} />

                                        <div className='d-flex flex-column flex-sm-row justify-content-between'>
                                          <div class="position-relative mb-4 me-0 me-sm-3">
                                            <label
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('New Edition')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_184_h w-100 mr-2"
                                              value={updatedUserEdition}
                                              onChange={(e) => setUpdatedUserEdition(e.target.value)}
                                            />
                                          </div>

                                          <div class="position-relative">
                                            <label
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Confirmation Edition')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_184_h w-100"
                                              value={confirmUserEdition}
                                              onChange={(e) => setConfirmUserEdition(e.target.value)}
                                            />
                                          </div>
                                        </div>

                                        <div class="d-flex justify-content-center">
                                          <div class="btn btn-Acheter mt-3 mb-4 px-4 py-1" onClick={() => handleSubmitUserEdition(product?.id,)}>
                                            {t('confirm')}
                                          </div>
                                        </div>

                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-50 signature text-center d-flex flex-column">
                            <p class="text-center m-0">{t('Signature')} :</p>
                            <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-1'>
                              <img src={signature} style={{ width: 80, marginTop: 40, alignSelf: 'center', marginTop: 0, objectFit: "contain" }} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="order-1 order-sm-2 d-flex justify-content-between btn-mobile-order mt-3"
                      >
                        <div class="writer-content d-flex align-items-center">
                          <img
                            src={marinoImage}
                            alt=""
                            class="me-2"
                          />
                          {/* <div class="author-name">
                            <h4 class="m-0">Marino Di Teana</h4>
                            <p class="m-0">@marino_di_teana</p>
                          </div> */}
                        </div>
                        {!auth?.id || !isProductOwner &&
                          <div class="d-flex justify-content-end">
                            <a href={`https://1-art.co/products/${product.productName.toLowerCase().replaceAll(' ', '-')}`} class="btn btn-Acheter mt-3 mb-4 px-4 py-1" >
                              {t('buy')}
                            </a>
                          </div>
                        }
                      </div>
                    </div>

                    <div class="detail" style={{ marginTop: auth?.id ? 30 : 0 }}>
                      <div dangerouslySetInnerHTML={{ __html: product?.productDescription }} />

                      <p class="lh-sm">
                      </p>
                    </div>

                    <div class="d-flex justify-content-end">
                      <div class="d-flex align-items-center">
                        <p class="views me-4 m-0">{viewCount || 0} {t('views')}</p>
                        <div class="tooltip" style={{ color: 'black', zIndex: 0 }}>
                          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
                            <img
                              src={copied ? DoneIcon : ShareIcon} //window.location.href
                              class="w_icons_views me-4"
                            />
                          </CopyToClipboard>
                          <span class="tooltiptext">{copied ? 'Copied' : 'Copy to ClipBoard'}</span>
                        </div>

                        <div onClick={() => postUserLikeUnlike()}>
                          <img
                            src={isLike ? LikesPleinIcon : LikeIcon}
                            class="w_icons_views me-4"
                          />
                        </div>
                        <div onClick={() => postUserHeartUnheart()}>
                          <img
                            src={isHeart ? WishListPleinIcon : WishListIcon}
                            class="w_icons_views me-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="fixedBottomContainer" style={{ position: 'relative' }}>
                <div class={`hidden_in_mobile my-5 ${isFixed ? 'fixed_bottom' : ''}`}>
                  <div class={`row ${menuVisible ? 'd-none' : 'information_toggle'}`}>
                    <div class="col-md-12 col-lg-12">
                      <div class="menu">
                        <ul class="d-flex justify-content-between list-unstyled m-0">
                          <li class="me-5 cursor_pointer">
                            <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                              <h6
                                class="m-0 fw-normal cursor_pointer"
                              >
                                {t('news_on_instragram')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                              >
                                <div class="modal-content">
                                  <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                                  <div class="modal-body">
                                    <div class="container text-center my-3">
                                      <div
                                        class="row mx-auto my-auto justify-content-center"
                                      >
                                        <div
                                          id="recipeCarousel"
                                          class="carousel slide"
                                          data-bs-ride="carousel"
                                        >
                                          <div class="carousel-inner" role="listbox">
                                            <div class="carousel-item active">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={NewsImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={CollectionImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    {/* <img
                                                    src={teamImage}
                                                    class="img-fluid"
                                                  /> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOFourImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="prevContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="prev"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_left"
                                              />
                                            </a>
                                          </div>
                                          <div class="nextContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="next"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_right"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <h6
                              class="m-0 fw-normal cursor_pointer information_onClick"
                              onClick={toggleMenu}
                            >
                              {t('information')}
                            </h6>
                          </li>
                          <li class="mx-5">
                            <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                              <h6
                                class="m-0 fw-normal cursor_pointer"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal1"
                              >
                                {t('Contact')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal1"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>

                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Entreprise')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('reasonForRequest')}</label>
                                            <select
                                              class="form-select custom-select w_400 w-100"
                                              id="inputGroupSelect01"
                                            >
                                              <option value="1">Service client</option>
                                              <option value="2">
                                                {t('pressCommunication')}
                                              </option>
                                              <option value="3">
                                                {t('partnerShip')}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label class="switch me-2" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t("conditions d'utilisation.")}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                              <h6 class="m-0 fw-normal cursor_pointer">
                                {t('artist')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal2"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <button style={{ zIndex: 1000 }} type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('photos')}</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label
                                                class="switch me-2"
                                                for="checkbox2"
                                              >
                                                <input type="checkbox" id="checkbox2" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t(`conditions d'utilisation.`)}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5 d-flex justify-content-between list-unstyled m-0">
                            <h6 class="m-0 fw-normal" >{t('Suivre 1-ART')}</h6>
                            <li class="mx-3"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                            <li class="mx-3"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                            <li class="mx-3"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                            <li class="mx-3"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                            <li class="mx-3"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                  {menuVisible && (
                    <ul
                      class="d-flex justify-content-between list-unstyled information_menu"
                    >
                      <li class="me-5 cursor_pointer">
                        <h6 class="m-0 fw-normal cursor_pointer" onClick={toggleMenu}>{t('information')}</h6>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                          <h6 class="m-0 cursor_pointer">{t('order_tracing')}</h6>
                        </Link>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link className="text-decoration-none text-black" to="/faq">
                          <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                        </Link>
                      </li>

                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/privacy-policy">
                          <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                        </Link>
                      </li>
                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/terms-and-service">
                          <h6 class="m-0 cursor_pointer">
                            {t('termOfService')}
                          </h6>
                        </Link>
                      </li>
                    </ul>)}
                </div>
              </div>

              <div class={`row flex_custom ${isFixed && "mt-5"}`}>
                <div class="col-md-12 col-lg-8 order-2 order-sm-1">
                  <div class="marino-main">
                    <div class="marino-since">
                      <div class="d-flex justify-content-between flex_custom">
                        <div class="date-name">


                          {metaInformation && metaInformation
                            .filter(item => item.artist_name)
                            ?.[0].artist_name === 'Bathsheba Grossman' ?
                            <div style={{ flexDirection: 'row', display: 'flex', }}>
                              <div style={{ background: '#EFEFEF', width: 50, height: 50, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -10, marginRight: 10 }}>
                                <img src={TeamIcon} style={{ width: 40, height: 40, borderRadius: 50 }} />
                              </div>
                              <h4 class="fw-bold m-0">{metaInformation && metaInformation
                                .filter(item => item.artist_name)
                                .map(item => item.artist_name)}</h4>
                            </div>
                            :
                            <div style={{ background: '#EFEFEF', width: 50, height: 50, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -10, marginRight: 10 }}>
                              <div style={{ width: 40, height: 40, borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', background: '#f1f1f1', }}>
                                <img src={Logo} style={{ minWidth: '100%', }} />
                              </div>
                            </div>
                            // <img src={Logo} style={{ width: 50, }} />
                          }
                        </div>
                        <ul
                          class="d-flex justify-content-between list-unstyled flex_content_center"
                        >
                          {metaInformation && metaInformation
                            .find(item => item.artist_instagram)?.artist_instagram && metaInformation
                              .find(item => item.artist_instagram)?.artist_instagram != undefined &&
                            <li class="mx-3"><a target="_blank" href={metaInformation.find(item => item.artist_instagram)?.artist_instagram} style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                          }

                          {metaInformation && metaInformation
                            .find(item => item.artist_facebook)?.artist_facebook && metaInformation
                              .find(item => item.artist_facebook)?.artist_facebook != undefined &&
                            <li class="mx-3"><a target="_blank" href={metaInformation.find(item => item.artist_facebook)?.artist_facebook} style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                          }

                          {metaInformation && metaInformation
                            .find(item => item.artist_youtube)?.artist_youtube && metaInformation
                              .find(item => item.artist_youtube)?.artist_youtube != undefined &&
                            <li class="mx-3"><a target="_blank" href={metaInformation.find(item => item.artist_youtube)?.artist_youtube} style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                          }

                          {metaInformation && metaInformation
                            .find(item => item.artist_twitter)?.artist_twitter && metaInformation
                              .find(item => item.artist_twitter)?.artist_twitter != undefined &&
                            <li class="mx-3"><a target="_blank" href={metaInformation.find(item => item.artist_twitter)?.artist_twitter} style={{ color: '#000' }}><i class="fa-brands fa-x-twitter"></i></a></li>
                          }


                        </ul>
                      </div>
                    </div>
                    <div class="marino-content">
                      <p class="lh-sm">
                        {/* {metaInformation && metaInformation.length > 0 &&
                          <RenderNode node={JSON.parse(metaInformation
                            .filter(item => item.long_description)
                            .map(item => item.long_description))} />
                        } */}
                        {/* {cookies.get("i18next") === "fr"} */}
                        {/* {metaInformation && metaInformation.length > 0 && */}
                        {cookies.get("i18next") === "fr" ?
                          <>
                            <div dangerouslySetInnerHTML={{ __html: selectTranslation }}></div>
                          </>
                          :
                          metaInformation && metaInformation.length > 0 && <RenderNode node={JSON.parse(selectTranslation)} />
                        }
                        {/* } */}

                      </p>

                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 order-1 order-sm-2">
                  <div class="marino-img d-flex justify-content-center">
                    {metaInformation && metaInformation
                      .filter(item => item.artist_name)
                      ?.[0].artist_name === 'Bathsheba Grossman' ?
                      <img
                        src={TeamIcon}
                        alt="marino_img"
                        className='h-auto'
                      />
                      :
                      <img
                        src={Logo}
                        alt="marino_img"
                        className='w-50 h-50'
                        style={{ borderRadius: 0 }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          : loading ? <div> <Loader /></div> :
            <div style={{ textAlign: 'center', fontWeight: 'bold', alignSelf: 'center', marginTop: 100 }}>
              {t('noProductFound')}
            </div>}
      </div>
      {invoiceModal &&
        <OrderInvoices data={invoiceDetail} onClickClose={() => handleCloseInvoiceModal()} />
      }
    </React.Fragment>
  )
}

export default Home