import axios from 'axios';

const API_BASE_URL = 'https://one-art-backend-0291e4efdff9.herokuapp.com';
// const API_BASE_URL = 'http://192.168.10.27:5001';

class APIService {
  constructor() {
    this.api = axios.create({
      baseURL: API_BASE_URL,
    });
  }

  async signup(firstName, lastName, email, password) {
    try {
      const response = await this.api.post('/auth/register', {
        firstName,
        lastName,
        email,
        password
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async login(email, password) {
    try {
      const response = await this.api.post('/auth/sign-in', { email, password });
      return response.data;
    } catch (error) {
      console.log('error ', error);
      throw error;
    }
  }

  async getOrder() {
    try {
      const response = await this.api.get('/shopify/orders');
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getAllProducts() {
    try {
      const response = await this.api.get('/shopify/products');
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getCollections(userToken) {
    try {
      const response = await this.api.get(`/shopify/collections?token=${userToken}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getSingleProduct(nfcNumber) {
    try {
      const response = await this.api.get(`/shopify/collections/product/${nfcNumber}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async saveDragImages(productId, message, images) {
    try {
      const response = await this.api.put(`/shopify/save-drag-images`, {
        productId,
        message,
        images
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async customizeProduct(productId, message, images, serialNumber) {
    try {
      const response = await this.api.put(`/shopify/customizeProduct`, {
        productId,
        message,
        images,
        serialNumber
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateOrder(orderNumber, serialNumber, id) {
    try {
      const response = await this.api.put('/shopify/orders', {
        orderNumber: orderNumber,
        serialNumber: serialNumber,
        id: id
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadImage(images) {
    const formData = new FormData()
    images.forEach(file => {
      formData.append('image', file)
    });
    try {
      const response = await this.api.post('/shopify/uploadImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadVideo(formData) {
    try {
      const response = await this.api.post('/shopify/uploadVideo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadVideoFile(formData) {
    try {
      const response = await this.api.post('/shopify/uploadVideoFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async uploadBase64Image(images) {
    const formData = new FormData()
    formData.append('image', images)
    try {
      const response = await this.api.post('/shopify/uploadBase64Image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async verifyToken(data) {
    try {
      const response = await this.api.post(`/auth/verify-token`, {
        token: data.token
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async resetUserToken(email) {
    try {
      const response = await this.api.post(`/auth/remove-token`, {
        email
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async transactionOtp(message, firstName, lastName, email, ownerEmail) {
    try {
      const response = await this.api.post('/shopify/transactionOtp', {
        message,
        firstName,
        lastName,
        email,
        ownerEmail
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async verifyTransactionOtp(email, otp, currentUserEmail, serialNumber,transactionMessage,transactionFirstName,transactionLastName,productId) {
    try {
      const response = await this.api.post('/shopify/verify-transaction-otp', {
        email:email.toLowerCase(),
        otp,
        currentUserEmail:currentUserEmail.toLowerCase(),
        serialNumber,
        transactionMessage,
        transactionLastName,
        transactionFirstName,
        productId
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async deleteImage(productId, imageUrl) {
    try {
      const response = await this.api.put(`/shopify/delete-image`, {
        productId,
        imageUrl
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async sendGift(giftFirstName, giftLastName, giftEmail, giftMessage, giftDiff, uploadedImagesUrl,ownerEmail) {
    try {
      const response = await this.api.post('/shopify/giftOtp', {
        message:giftMessage,
        firstName:giftFirstName,
        lastName:giftLastName,
        email:giftEmail,
        ownerEmail:ownerEmail
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async verifyGiftOtp(email, otp, currentUserEmail, serialNumber,giftMessage,giftFirstName,giftLastName,uploadedImagesUrl,productId,giftDiff,dateTime) {
    try {
      const response = await this.api.post('/shopify/verify-gift-otp', {
        email:email.toLowerCase(),
        otp,
        currentUserEmail:currentUserEmail.toLowerCase(),
        serialNumber,
        giftMessage,
        giftLastName,
        giftFirstName,
        images:uploadedImagesUrl,
        productId,
        toDiff:giftDiff,
        dateTime
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateUserEdition(userEditions, id) {
    try {
      const response = await this.api.put('/shopify/user-edition', {
        userEditions: userEditions,
        id: id
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async likeUnlike(data) {
    try {
      const response = await this.api.post(`/auth/user-like`, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async heartUnHeart(data) {
    try {
      const response = await this.api.post(`/auth/user-heart`, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getLikeUnlike(data) {
    try {
      const response = await this.api.post(`/auth/retrive-user-like`, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }

}

export default new APIService();
