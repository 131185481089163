import React from 'react'
import QRCode from 'react-qr-code'

const Qrcode = () => {
  return (
    <div
      className='d-flex justify-content-center'
    >
      <QRCode
        size='80'
        value='123456'
      // value={props.value}
      // className='qr_style_inn'
      />
    </div>
  )
}

export default Qrcode