import React, { useEffect, useState } from 'react'
import APIService from '../networks/APIService'
import Loader from './Loader/Loader'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

const DataBase = () => {
  const navigate = useNavigate()
  const [orders, setOrders] = useState([])
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    getOrders()
  },[])


  const getOrders = async () => {
    const temp = localStorage.getItem('user');
    const auth = JSON.parse(temp);
    try {
      const response = await APIService.getOrder();
      const filteredArray = response.filter(item => item.productOwnerEmail === auth?.email || auth?.email === 'aghaali123811@gmail.com');
      
    setOrders(response)
    if(filteredArray && filteredArray.length > 0){
      setOrders(response)
    }else{
      toast(t('notAuthorized'))
      setTimeout(() => {
        navigate(-1)
      }, 500);
    }
    
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  
  const updateOrders = async (e,i,o) => {
    setLoading(true)
    try {
      const response = await APIService.updateOrder(e,i,o);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  
  const handleChange = (e,id) => {
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].id === id) {
        orders[i].serialNumber = e;
        break; // Stop loop once the object is found and updated
      }
    }
  }
  return (
    <div className='container h-available'>
      <div className='row h-100'>
      <div className='col-md-4'><div className='bg-secondary h-100 p-4'></div></div>
      <div className='col-md-8'>
        <div className='bg-secondary h-100 p-4'>
        <h3 className='mb-3'>{t('1-ART UCP DATABASE')}</h3>
        {loading ? <div><Loader /></div> : orders.length > 0 ? orders.map((order, index) => (
            <div key={index} className='d-flex flex-row align-items-end flex-wrap'>
              <div className="flex flex-col me-4 col-12 col-md-3 mb-2">
                <label htmlFor={`orderNumberInput-${index}`}>
                  {t('orderNumber')}:
                </label>
                <input disabled id={`orderNumberInput-${index}`} value={order.orderNumber} title='Order Number' className='form-control border-0 rounded-0' style={{height: 40}} />
              </div>
              <div className="flex flex-col me-4 col-12 col-md-3 mb-2">
                <label htmlFor={`orderNumberInput-${index}`}>
                  {t('serialNumber')}:
                </label>
                <input id={`orderNumberInput-${index}`} defaultValue={order.serialNumber} disabled={order.serialNumber === "" ? false : true} title='Order Number' className='form-control border-0 rounded-0' style={{height: 40}} onChange={(e)=>handleChange(e.target.value,order?.id)} />
              </div>
              {order.serialNumber === "" &&
            <button className='btn btn-light rounded-0 mb-2' style={{height: 40}} onClick={()=>updateOrders(order.orderNumber,order.serialNumber,order.id)}>{t('linkUcpPage')}</button>
        }
          </div>
        )) : <div style={{textAlign:'center',fontWeight:'bold'}}>
          {t('noOrderFound')}
          </div>}
        </div>
      </div>
    </div>
    </div>
  )
}

export default DataBase
