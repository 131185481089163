import React, { useEffect, useState } from 'react'

const AddItems = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const [quantity, setQuantity] = useState(1);

  // Function to increment the quantity
  const incrementQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  // Function to decrement the quantity
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const currentSlide = (n) => {
    showSlides(n);
  };

  const showSlides = (n) => {
    let newIndex = n;
    const slides = document.querySelectorAll(".slides");
    const dots = document.querySelectorAll(".slide-thumbnail");

    if (n > slides && slides.length) {
      newIndex = 1;
    }
    if (n < 1) {
      newIndex = slides && slides.length;
    }

    setSlideIndex(newIndex);

    slides.forEach((slide) => {
      slide.style.display = "none";
    });
    dots.forEach((dot) => {
      dot.classList.remove("active");
    });

    slides[newIndex - 1].style.display = "block";
    // dots[newIndex - 1].classList.add("active");
  }

  useEffect(() => {
    showSlides(1);
  }, []);

  useEffect(() => {
    const items = document.querySelectorAll(".carousel .carousel-item");
    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (let i = 1; i < minPerSlide; i++) {
        if (!next) {
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  }, []);

  return (
    <div className="bg-light">
      <div className="container">
        <div className="row p-5 cart_padd_in_mobile">
          <div className="col-md-7">
            {/* main images */}
            <div class="holder mb-4">
                    <div class="slides">
                      <img
                        src="../assets/img/Website Assets/GTO Carousel/GTO 1.png"
                        style={{height: 'auto'}}
                      />
                    </div>

                    <div class="slides">
                      <img
                        src="../assets/img/Website Assets/GTO Carousel/GTO 2.png"
                        style={{height: 'auto'}}
                      />
                    </div>

                    <div class="slides">
                      <img
                        src="../assets/img/Website Assets/GTO Carousel/GTO 3.png"
                        style={{height: 'auto'}}
                      />
                    </div>

                    <div class="slides">
                      <img
                        src="../assets/img/Website Assets/GTO Carousel/GTO 4.png"
                        style={{height: 'auto'}}
                      />
                    </div>
                  </div>
            {/* thumnails in a row */}
            <div
                  class="d-flex justify-content-center align-items-center flex-wrap"
                >
                  <img
                    class="slide-thumbnail-cart"
                    src="../assets/img/Website Assets/GTO Carousel/GTO 1.png"
                    onClick={() => currentSlide(1)}
                    alt="Caption Two"
                  />
                  <img
                    class="slide-thumbnail-cart mx-2"
                    src="../assets/img/Website Assets/GTO Carousel/GTO 2.png"
                    onClick={() => currentSlide(2)}
                    alt="Caption Three"
                  />
                  <img
                    class="slide-thumbnail-cart mx-2"
                    src="../assets/img/Website Assets/GTO Carousel/GTO 3.png"
                    onClick={() => currentSlide(3)}
                    alt="Caption Four"
                  />
                  <img
                    class="slide-thumbnail-cart"
                    src="../assets/img/Website Assets/GTO Carousel/GTO 4.png"
                    onClick={() => currentSlide(4)}
                    alt="Caption Five"
                  />
                </div>
          </div>
          <div className="col-md-5">
            <div className="cart-description">
              <h2>Lorem Ipsum</h2>
              <p>00.00€</p>
              <p className="mb-1 m-0">Quantité</p>
              <div className="cart-quantity-border d-flex justify-content-around align-items-center mb-4">
                <button className="btn btn-dec" onClick={decrementQuantity}>-</button>
                <p className="m-0">{quantity}</p>
                <button className="btn btn-inc" onClick={incrementQuantity}>+</button>
              </div>
              <button className="btn add-to-cart mb-2">Ajouter au panier</button>
              <button className="btn btn-buy-cart mb-4">Acheter</button>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus at,
                soluta harum autem illo iste! Officiis ab soluta, neque assumenda
                commodi omnis. Ab, aspernatur sed! Reprehenderit deserunt nemo
                voluptate assumenda!
              </p>
              <p>100 mm/100mm</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default AddItems