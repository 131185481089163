import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
// Assets
import Icon from '../assets/img/Website Assets/Icons/Fleche Swap - noir.png'
import NewsImage from '../assets/img/Website Assets/GTO Carousel/GTO 1.png'
import GTOImage from '../assets/img/Website Assets/GTO Carousel/GTO 2.png'
import CollectionImage from '../assets/img/Website Assets/Collection Pics/GTO Collection Computer.png'
import GTOFourImage from '../assets/img/Website Assets/GTO Carousel/GTO 4.png'
import FlecheImage from '../assets/img/Website Assets/Icons/Fleche.png'
import BurgerIcon from '../assets/img/Website Assets/Icons/burger-bar.png'
import { Link } from 'react-router-dom'

const TermsAndService = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Adjust the threshold as needed
      const threshold = 670;

      if (documentHeight - scrollPosition - windowHeight < threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  return (
    <>
    <div class="container">
       <div className="terms-container p-5">
      <h1 className='text-center my-5'>TERMS & CONDITIONS OF 1-FAB</h1>
      <p>Revised version, 9 August 2024</p>
      <p>
        This document contains the terms and conditions (the “Terms and
        Conditions”) on which 1-FAB supply any of its products (each a “Product”)
        that are listed on its website <a href="http://www.1-art.co">http://www.1-art.co</a> (the “Site”) or are sold
        otherwise to you. Please read these Terms and Conditions carefully
        before ordering any Products. You should understand that by ordering
        any of our Products, you agree to be bound by these Terms and
        Conditions.
      </p>

      <h2>1. APPLICABILITY</h2>
      <ol type="a">
        <li>
          These terms and conditions apply to all offers and all agreements
          regarding the orders of 1-FAB.
        </li>
        <li>
          In addition to these General Terms and Conditions, explicitly defined
          Additional Terms and Conditions may apply to certain services and/or
          products. Should there be deviations between the General Terms and
          Conditions and the Additional Terms and Conditions, the Additional
          Terms and Conditions will prevail over the General Terms and
          Conditions, unless explicitly stated otherwise.
        </li>
        <li>
          If any provision of these terms and conditions are invalid or
          invalidated, the other provisions of these General Terms and
          Conditions will remain in full force and 1-FAB and its contracting
          party will enter into consultation for the purpose of agreeing on new
          provisions to replace the invalid or invalidated provisions, where
          the objective and meaning of the invalid or invalidated provision are
          considered as much as possible.
        </li>
        <li>
          Deviation of these General Terms & Conditions is only possible after
          explicit confirmation by 1-FAB in writing, in which case the other
          provisions of these general terms and conditions will remain in full
          force.
        </li>
        <li>
          The applicability of the general terms and conditions invoked by the
          contracting party is explicitly rejected, unless explicitly confirmed
          in writing by 1-FAB.
        </li>
        <li>
          “Contracting party” is defined as every natural person or legal person
          with a contractual relationship with 1-FAB.
        </li>
        <li>1-FAB has the right to adjust these General Terms and Conditions from time to time.</li>
        <li>
          By placing an order, the contracting party accepts these General Terms
          and Conditions as well as all other rights and duties stated on the
          website of 1-FAB.
        </li>
        <li>
          1-FAB is allowed to outsource activities to third parties when
          carrying out an agreement with the contracting party.
        </li>
      </ol>

      <h2>2. OFFERS AND AGREEMENTS</h2>
      <ol type="a">
        <li>
          Offers or quotations should be regarded as an invitation to the
          potential buyer to make a purchase. 1-FAB is in no way bound to such
          matters, unless explicitly confirmed in writing by way of a written
          order confirmation. Acceptance of the invitation by the potential
          buyer to make an offer counts as a valid offer and leads only to an
          agreement in case of fulfillment of the following aspects of this
          article.
        </li>
        <li>Specific offers are valid as long as the stock or production schedule permits.</li>
        <li>A personalised quotation is valid for two (2) weeks, unless another term is mentioned in the quotation.</li>
        <li>
          An offer from the potential buyer as mentioned in article 2.a. is made
          when:
          <ul>
            <li>
              the potential buyer has entered his personal data on the website
              and the data has been sent electronically to 1-FAB and received by
              1-FAB, or;
            </li>
            <li>
              the potential buyer explicitly indicated the desire, by word,
              telefax, or through writing to receive a certain product and/or
              service, or;
            </li>
            <li>
              a quotation is signed by the buyer and received by 1-FAB in case
              1-FAB issued a personalised quotation.
            </li>
          </ul>
        </li>
        <li>
          An agreement, including any change or supplementation hereto, takes
          first binding effect for 1-FAB when an order confirmation has been
          issued to the buyer, by e-mail or other means. This agreement can be
          revoked by 1-FAB in case the buyer does not meet the requirements or
          has failed to do so in the past. In that case, 1-FAB will report such
          findings to the buyer within ten (10) days after receiving the order.
        </li>
        <li>
          Buyer and 1-FAB explicitly agree that, when using electronic means of
          communication, a valid agreement comes into effect after meeting the
          requirements specified in article 2.d. and 2.e. In particular the lack
          of a written signature does not reduce the binding force of the offer
          and the acceptance thereof. In that case the electronic files of 1-FAB
          count, as far as the law allows, as a presumption of proof.
        </li>
        <li>
          Buyer can't add or remove any item to an order that has already been
          placed. Orders won't be combined, before or after they're placed,
          unless agreed upon in writing by 1-FAB. All items that need to be
          combined have to be in one order.
        </li>
        <li>
          1-FAB reserves the right, without prior notice, to limit the order
          quantity on any product or service and/or to refuse service to the
          buyer. 1-FAB also may require verification of information prior to the
          acceptance and/or shipment of any order.
        </li>
        <li>
          Information, images, oral announcements, records, etc. regarding all
          offers and the most relevant characteristics of the products that are
          provided by telephone or e-mail are always as accurate as possible.
          1-FAB does not guarantee that all offers and products completely
          correspond to the provided information. Deviations can never lead to
          reimbursement or dissolvement of the agreement.
        </li>
      </ol>

      <h2>3. PRICES</h2>
      <ol type="a">
        <li>All prices are expressed in Euros, in accordance with the legal regulations, unless specifically mentioned.</li>
        <li>Special offers are only valid as long as the stock permits.</li>
        <li>
          The buyer owes the price as defined by 1-FAB in the order confirmation
          in accordance with article 2.e. of these General Terms and Conditions.
          Any (manipulation) errors in the quotation, such as evident flaws, can
          be corrected by 1-FAB, even after reaching the agreement.
        </li>
        <li>
          Transportation costs will be separately mentioned. Special rates apply
          for deliveries outside the Netherlands. Transportation costs may not
          be mentioned on the offer, quotation or order confirmation, but will
          be on the invoice nonetheless.
        </li>
        <li>
          When the prices of the offered products and/or services have increased
          during the period between ordering and execution of the order, the
          buyer is entitled to cancel the order or dissolving the agreement
          within ten (10) days after announcement of the price increase by
          1-FAB.
        </li>
        <li>
          In the event that a 1-FAB product is mistakenly listed at an incorrect
          price, 1-FAB reserves the right to refuse or cancel any orders placed
          for product listed at the incorrect price. 1-FAB reserves the right to
          refuse or cancel any such orders whether or not the order has been
          confirmed and/or credit card charged. If buyers credit card has
          already been charged for the purchase and the order is cancelled,
          1-FAB shall issue a credit to the credit card account in the amount of
          the incorrect price.
        </li>
        <li>
          When a currency other than Euros is used, 1-ART will determine the
          fair conversion rate of that day, which will remain valid for the
          duration of the offer or until the order or invoice have been paid.
        </li>
      </ol>

      <h2>4. PAYMENT</h2>
      <ol type="a">
        <li>Orders should be paid by 100% down payment. 1-FAB can include other payment options in the future. Other payment options will be announced on the website.</li>
        <li>
          In case 1-FAB agreed on an alternative payment period, the expiration
          of this term automatically leads to the omission of the buyer.
          Alternative payment periods can only be agreed on in writing under
          special conditions.
        </li>
        <li>
          Non-payment or untimely payment by the buyer leads to a due interest
          of 1.5% per month to be calculated from the date of expiration of the
          payment until the moment of payment in full. All judicial and
          extrajudicial costs that 1-FAB incurred as a result of buyer’s failure
          to meet his payment obligations will be borne by the buyer. In that
          case, buyer owes an amount equal to 15% of the outstanding amount,
          with a minimum of one hundred Euro (€ 100.00).
        </li>
        <li>
          1-FAB is entitled to postpone the delivery and/or collection of the
          products until full payment of the outstanding amounts has been
          received.
        </li>
        <li>
          If buyer’s payment is rejected by the bank or credit card company, 1-FAB
          has the right to dissolve the agreement with immediate effect or to
          postpone (further) delivery until buyer’s payment obligations have
          been met.
        </li>
        <li>Credit card payments are made using Stripe (payment provider). During the payment process, Stripe verifies the authenticity of the card and checks whether there is sufficient balance to pay the purchase amount. Stripe then authorises the payment and executes it on behalf of 1-FAB. After this authorisation, the payment will be made, and the order will be confirmed by email. The credit card payment process is conducted entirely via a secure SSL connection (Secure Socket Layer).</li>
      </ol>





      <h2>5. DELIVERY AND DELIVERY TIME</h2>
      <ol type="a">
        <li>	Orders will be delivered as quickly as possible, depending on availability of the product. 1-FAB aims to send the products within fourteen (14) working days after receiving the order and down payment. The final delivery date is thirty (30) days after receiving the order, not including down payments, at which the final delivery date is thirty (30) days after receiving the payment. An agreed delivery time is always indicative and neither a deadline, nor can any rights be derived from exceeding the delivery time. 1-FAB can announce information regarding delivery times on the website or by other written means. Such information is always indicative.</li>
        <li>
        	In the exceptional case that items cannot be shipped in the desired period, 1-FAB will contact the buyer pro-actively to try to come to a solution on how to proceed, including the option to cancel the order.
        </li>
        <li>
        	Orders are shipped to the delivery address noted in the buyer order. For international orders, buyer is also responsible for any tariffs, taxes, fees, VAT, duties due upon receipt.
        </li>
        <li>
        	Immediately after the goods have been delivered, the buyer bears the risk for all direct and indirect damage that maybe caused to or by these goods or components. The buyer also bears the risks of transportation from the moment of delivery. Buyer is responsible for filing any claims against carriers for damaged and/or lost shipments.
        </li>
        <li>
        	1-FAB is not responsible for delays, loss or damage from shipments sent to incorrect, invalid addresses or correct addresses. Orders may end up being returned for various reasons including: non-payment of tariffs, incorrect address, unclaimed at post office, etc. If a package is returned or rejected, the buyer will be contacted and will have to pay for re-shipment postage.
        </li>
        </ol>





        <h2>6. RETURN POLICY</h2>
      <ol type="a">
        <li>The buyer may exercise his right to return within seven (7) working days after delivery of the product, without penalty and without stating reasons. The buyer can claim guarantee provisions only when the product and the packaging are returned within this period in original, complete, undamaged and unused conditions. All sent documentation, proof of guarantee and packaging materials should be included in the return delivery.</li>
        <li>
        1-FAB is never liable for any damage, theft or loss of the product or packaging during the return delivery.
        </li>
        <li>
        	The costs of the return delivery of the product are for the buyer.
        </li>
        <li>
        	In case the buyer exercised his right to return as mentioned in the previous articles, 1-FAB will have to reimburse the cash value of the delivered goods within thirty (30) days after the products have returned to 1-FAB.
        </li>
        <li>
        	Before returning a product to 1-FAB the customer will inform 1-FAB. about this. 1-FAB will then provide a form for returning. If additional costs are to be paid by the customer, the customer will be informed about this before such costs are made.
        </li>
        <li>
        	Certain products are made to order for the customer. These products are carefully made by 1-FAB and cannot be returned, unless any defect is proven.
        </li>
        <li>	Upon delivery of the goods the buyer inspects the conditions of the goods. In case damage has been inflicted to the goods of materials, the buyer will take all possible provisions to obtain compensation from the transporter.</li>
        <li>	All goods are checked before they are sent out. In the unlikely event that goods are faulty, they must be returned to 1-FAB at the address listed on the order invoice. Returning products is only possible in combination with an original invoice and original, complete and undamaged products. See also 6.e.</li>
        <li>	Missing parts should be mentioned in writing or by e-mail to 1-FAB within seven (7) days after the delivering date.</li>
        <li>	Defective products manufactured by 1-FAB will be replaced with the same, if they are send back within fourteen (14) days after delivering and if 1-FAB concludes that the product is indeed defective. 1-FAB will not accept goods that have clearly been used more than the amount needed to evaluate their functionality. If the goods are found to be in working condition, and the lack of functionality is a result of damaged incurred after it is shipped, no refund will be made, but the goods will be returned to the buyer at their expense. If there is evidence that the product has been modified or that a fix has been done on it, 1-FAB will not be made responsible and therefore will not provide an exchange.</li>
        <li>	Order cancelations or refunds apply with the following conditions: 1. - When 1-FAB. can no longer provide one or more items of the actual order (i.e. discontinued parts). 2. - When one or more items of the actual order are not expected to be shipped within 30 days or more.</li>
        <li>	Return shipment is the responsibility of the buyer. All returns are credited financially the same way as the order was paid or with a replacement product. A return merchandise authorization (RMA) is required for returns.</li>
        <li>	Return shipment is only possible if 1-FAB has been informed beforehand with a clear definition of the reason(s) of returning the shipment, be it partially or complete. The buyer cannot claim any rights if the goods arrived unannounced.</li>
        </ol>







        <h2>8. OWNERSHIP</h2>
      <ol type="a">
        <li>	The ownership of the goods, whether handled or unhandled, is transferred to the contracting party at the moment that the buyer pays 1-FAB the full amounts payable pursuant to the agreement, including interest, costs and damages from products and/or services from this order, previous orders and future orders.</li>
        </ol>



        <h2>9. GUARANTEES AND LIABILITY</h2>
      <ol>
        <li>
          1-FAB is never bound to financially compensate the buyer or other
          parties, unless the damage was caused by intention or guilt.
        </li>
        <li>
          In case 1-FAB is obliged to financially compensate the buyer, the
          amount will always be limited to the invoice amount related to the
          product and/or service that caused the damage.
        </li>
        <li>
          The guarantee of 1-FAB does not apply if:
          <ul>
            <li>
              the defects are (partly) the result of normal wear, injudicious or
              incorrect handling or use, injudicious or incorrect maintenance.
            </li>
            <li>
              the product is employed for purposes other than normal purposes or
              used incorrectly.
            </li>
            <li>
              the buyer or the end user does not strictly observe the operating
              instructions provided by 1-FAB.
            </li>
            <li>
              the original invoice is missing, modified, or made unreadable.
            </li>
          </ul>
        </li>
        <li>
          When guarantee provisions are exercised, 1-FAB can do the following:
          <ul>
            <li>adjust the amount on the invoice.</li>
            <li>
              replace the delivered item by an article with equal specifications,
              or repair the delivered item, in which case the delivered item
              should be sent back to 1-FAB.
            </li>
            <li>
              take back the delivered item and revoke the agreement, while
              reimbursing the paid amount by the buyer, without being obliged to
              financially compensate the buyer for any damage. The buyer is
              obliged to give 1-FAB SAS three (3) possibilities to repair any
              deficiencies.
            </li>
          </ul>
        </li>
        <li>
          The buyer does not hold 1-FAB liable for any claims resulting from
          third parties, unless the law strictly prohibits such damages and costs
          to be accounted to the buyer.
        </li>
        <li>
          It is possible that 1-FAB SAS places links on its website to other
          websites that could be of interest for a visitor. Such links are solely
          informative. 1-FAB is not liable for the content of the linked websites
          or the usage thereof.
        </li>
      </ol>

      <h2>10. FORCE MAJEURE</h2>
      <ol>
        <li>
          In case of force majeure, 1-FAB is not obliged to fulfil its
          obligations to the buyer. The respective obligations will be postponed
          for the entire duration of the force majeure.
        </li>
        <li>
          In these terms and conditions, force majeure is understood to mean any
          circumstance that is independent of the will of 1-FAB even if this
          could have been foreseen when the agreement was entered into, which
          temporarily or permanently hinders the fulfilment of the agreement,
          including but not limited to war, a threat of war, civil war, riots,
          industrial actions, work member exclusion, transport difficulties,
          fire, days not worked because of unsuitable weather and other
          disruptions to the business of 1-FAB or its suppliers.
        </li>
      </ol>

      <h2>11. INTELLECTUAL PROPERTY</h2>
      <ol>
        <li>
          The buyer explicitly acknowledges that all intellectual or industrial
          property rights relating to the products to be delivered pursuant to
          the agreement and or associated designs, documentation, reports,
          offers and associated preparatory material lie exclusively with 1-FAB,
          suppliers or other entitled parties.
        </li>
        <li>
          Intellectual property rights include patents, copyrights, trademarks
          and other (intellectual property) rights, including technical and
          commercial know-how, methods and concepts.
        </li>
        <li>
          The buyer is not allowed to modify any intellectual property rights as
          described in this article, for instance multiplication without explicit
          written approval from 1-FAB its suppliers or other entitled parties.
        </li>
        <li>
          The entire content of the 1-FAB websites, including but not limited to
          text, graphics or code is copyrighted as a collective work under French
          law and other copyright laws, and is the property of 1-FAB The
          collective work includes work that is licensed to 1-FAB. Permission is
          granted to electronically copy and print hard copy portions of this
          site for the sole purpose of placing an order with 1-FAB or purchasing
          1-FAB products. The buyer may display and, subject to any expressly
          stated restrictions or limitations relating to specific material,
          download or print portions of the material from the different areas of
          the site solely for buyers own non-commercial use, or to place an order
          with 1-FAB or to purchase 1-FAB products. Any other use, including but
          not limited to the reproduction, distribution, display or transmission
          of the content of this site is strictly prohibited, unless authorized
          by 1-FAB.
        </li>
        <li>
          1-ART is a registered trademark and may only be used by third parties
          after explicit and written approval by 1-FAB.
        </li>
      </ol>

      <h2>12. PERSONAL DATA</h2>
      <ol>
        <li>
          1-FAB will process the personal data of the buyer in accordance with its
          privacy policy, which is available on the website.
        </li>
        <li>
          1-FAB fully respects the applicable laws and regulations regarding the
          processing of personal data. Its privacy statement is in accordance
          with EU law GDPR 2016/679.
        </li>
      </ol>

      <h2>13. APPLICABLE LAW AND RIGHTS</h2>
      <ol>
        <li>
          French law applies to the offers/agreement and further agreements.
          The applicability of the Vienna Sales Convention (CISG) and the 1980
          U.N. Convention on contracts for international sale of goods are
          explicitly excluded.
        </li>
        <li>
          All disputes, of whatever nature – including those that are only
          considered by one of the parties as such – which occur with reference
          to the offer/agreement and further agreements between the parties, are
          settled by a competent court in the district where 1-FAB is situated,
          unless the law explicitly appoints another court.
        </li>
        <li>
          Any cause of action or claim the buyer has with respect to the websites
          or otherwise with respect to 1-FAB (including but not limited to the
          purchase of 1-FAB products) must be commenced within one (1) year after
          the claim or cause of action arises. 1-FAB’s failure to insist upon or
          enforce strict performance of any provision of these terms and
          conditions shall not be construed as a waiver of any provision or
          right. Neither the course of conduct between the parties nor trade
          practice shall act to modify any of these terms and conditions. 1-FAB
          may assign its rights and duties under this Agreement to any party at
          any time without notice to the buyer.
        </li>
      </ol>

      <h2>14. USE OF THE WEBSITES</h2>
      <p>
        Harassment in any manner or form on the site, including via e-mail, chat,
        or by use of obscene or abusive language, is strictly forbidden.
        Impersonation of others, including a 1-FAB or other licensed employee,
        host, or representative, as well as other members or visitors on the site
        is prohibited. The buyer may not upload to, distribute, or otherwise
        publish through the website any content which is libelous, defamatory,
        obscene, threatening, invasive of privacy or publicity rights, abusive,
        illegal, or otherwise objectionable which may constitute or encourage a
        criminal offense, violate the rights of any party or which may otherwise
        give rise to liability or violate any law. The buyer may not upload
        commercial content on the site or use the site to solicit others to join
        or become members of any other commercial online service or other
        organization.
      </p>

      <h2>15. PARTICIPATION DISCLAIMER</h2>
      <p>
        1-FAB does not and cannot review all communications and materials posted
        to or created by users accessing the site and is not in any manner
        responsible for the content of these communications and materials. The
        buyer acknowledges that by providing the ability to view and distribute
        user-generated content on the site, 1-FAB is merely acting as a passive
        conduit for such distribution and is not undertaking any obligation or
        liability relating to any contents or activities on the site. However,
        1-FAB reserves the right to block or remove communications or materials
        that it determines to be (a) abusive, defamatory, or obscene, (b)
        fraudulent, deceptive, or misleading, (c) in violation of a copyright,
        trademark or; other intellectual property right of another or (d)
        offensive or otherwise unacceptable to 1-FAB in its sole discretion.
      </p>

      <h2>16. INDEMNIFICATION</h2>
      <p>
        The Buyer agrees to indemnify, defend, and hold harmless 1-FAB, its
        officers, directors, employees, agents, licensors and suppliers
        (collectively the "Service Providers") from and against all losses,
        expenses, damages and costs, including reasonable attorneys' fees,
        resulting from any violation of these terms and conditions or any
        activity related to buyers account (including negligent or wrongful
        conduct) by the buyer or any other person accessing the site using buyers
        Internet account.
      </p>

      <h2>17. MISCELLANEOUS</h2>
      <p>
        1-FAB resides at 60 rue François 1er, 75008 Paris and is registered at
        Chamber of commerce SIRET 98112557800014. Please send all correspondence
        regarding these General Terms and Conditions to 1-FAB SAS at the address
        mentioned above or the address in the place as mentioned in this Terms
        and Conditions.
      </p>

      <h2>18. PRIVACY POLICY</h2>
      <p>For our privacy policy please click onto this link.</p>

      <h2>19. ENTRY INTO FORCE</h2>
      <p>These terms and conditions will enter into force as of 12-08-2024.</p>




    </div>

    </div>
    <div id="fixedBottomContainer" style={{ position: 'relative' }}>
                <div class={`hidden_in_mobile my-5 ${isFixed ? 'fixed_bottom' : ''}`}>
                  <div class={`row ${menuVisible ? 'd-none' : 'information_toggle'}`}>
                    <div class="col-md-12 col-lg-12">
                      <div class="menu">
                        <ul class="d-flex justify-content-between list-unstyled m-0">
                          <li class="me-5 cursor_pointer">
                            <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                              <h6
                                class="m-0 fw-normal cursor_pointer"
                              >
                                {t('news_on_instragram')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                              >
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="container text-center my-3">
                                      <div
                                        class="row mx-auto my-auto justify-content-center"
                                      >
                                        <div
                                          id="recipeCarousel"
                                          class="carousel slide"
                                          data-bs-ride="carousel"
                                        >
                                          <div class="carousel-inner" role="listbox">
                                            <div class="carousel-item active">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={NewsImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={CollectionImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    {/* <img
                                                    src={teamImage}
                                                    class="img-fluid"
                                                  /> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOFourImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="prevContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="prev"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_left"
                                              />
                                            </a>
                                          </div>
                                          <div class="nextContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="next"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_right"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <h6
                              class="m-0 fw-normal cursor_pointer information_onClick"
                              onClick={toggleMenu}
                            >
                              {t('information')}
                            </h6>
                          </li>
                          <li class="mx-5">
                            <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                              <h6
                                class="m-0 fw-normal cursor_pointer"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal1"
                              >
                                {t('Contact')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal1"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>

                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Entreprise')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('reasonForRequest')}</label>
                                            <select
                                              class="form-select custom-select w_400 w-100"
                                              id="inputGroupSelect01"
                                            >
                                              <option value="1">Service client</option>
                                              <option value="2">
                                                {t('pressCommunication')}
                                              </option>
                                              <option value="3">
                                                {t('partnerShip')}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label class="switch me-2" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t("conditions d'utilisation.")}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                              <h6 class="m-0 fw-normal cursor_pointer">
                                {t('artist')}
                              </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal2"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('photos')}</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label
                                                class="switch me-2"
                                                for="checkbox2"
                                              >
                                                <input type="checkbox" id="checkbox2" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t(`conditions d'utilisation.`)}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5 d-flex justify-content-between list-unstyled m-0">
                              <h6 class="m-0 fw-normal" >{t('Suivre 1-ART')}</h6>
                              <li class="mx-3"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                  {menuVisible && (
                    <ul
                      class="d-flex justify-content-between list-unstyled information_menu"
                    >
                      <li class="me-5 cursor_pointer">
                        <h6 class="m-0 fw-normal cursor_pointer" onClick={toggleMenu}>{t('information')}</h6>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                          <h6 class="m-0 cursor_pointer">{t('order_tracing')}</h6>
                        </Link>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link className="text-decoration-none text-black" to="/faq">
                          <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                        </Link>
                      </li>

                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/privacy-policy">
                          <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                        </Link>
                      </li>
                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/terms-and-service">
                          <h6 class="m-0 cursor_pointer">
                            {t('termOfService')}
                          </h6>
                        </Link>
                      </li>
                    </ul>)}
                </div>
              </div>

      <div
              class="d-flex justify-content-center fixed-bottom bg-white hidden_in_desktop"
            >
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <span class="navbar-toggler-icon">
                  <img
                    src={BurgerIcon}
                    width="26"
                    height="25"
                  />
                </span>
              </button>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasTop"
                aria-labelledby="offcanvasTopLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close text-reset m-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body">
                  <div class="menu">
                    <ul
                      class="d-flex flex-column align-items-center list-unstyled mt-5"
                    >
                      <li class="mb-5">
                      <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                        <h6
                          class="m-0 cursor_pointer"
                        >
                          {t('news_on_instragram')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="container text-center my-3">
                                  <div
                                    class="row mx-auto my-auto justify-content-center"
                                  >
                                    <div
                                      id="recipeCarousel m-0 p-0"
                                      class="carousel slide"
                                      data-bs-ride="carousel"
                                    >
                                      <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={NewsImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={CollectionImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                {/* <img
                                                src={teamImage}
                                                class="img-fluid"
                                              /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOFourImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="prevContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="prev"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_left"
                                          />
                                        </a>
                                      </div>
                                      <div class="nextContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="next"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_right"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <h6
                          class="m-0 cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalResponsive1"
                        >
                          {t('information')}
                        </h6>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive1"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered bg-white w_85"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <ul
                                  class="d-flex flex-column align-items-center list-unstyled"
                                >
                                  <li class="mb-5 cursor_pointer">
                                    <h6 class="m-0 fw-normal cursor_pointer">
                                      {t('information')}
                                    </h6>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('order_tracing')}
                                    </h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link className="text-decoration-none text-black" to="/faq">
                                    <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/privacy-policy"> 
                                    <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/terms-and-service">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('termOfService')}
                                    </h6>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                        <h6
                          class="m-0 cursor_pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModalResponsive2"
                        >
                          {t('Contact')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive2"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div
                                        class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                      >
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red">{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div
                                        class="d-flex justify-content-between mb-3"
                                      >
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>

                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('bussiness')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('reasonForRequest')}</label>
                                        <select
                                          class="form-select custom-select w_400 w-100"
                                          id="inputGroupSelect01"
                                        >
                                          <option value="1">{t('customerService')}</option>
                                          <option value="2">
                                            {t('pressCommunication')}
                                          </option>
                                          <option value="3">
                                            {t('partnerShip')}
                                          </option>
                                        </select>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Vente')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"
                                        ></textarea>
                                      </div>
                                      <div
                                        class="d-flex justify-content-between align-items-center mb-2 text-start"
                                      >
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox">
                                            <input type="checkbox" id="checkbox" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                        <h6
                          class="m-0 cursor_pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModalResponsive3"
                          >
                          {t('artist')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive3"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div class="transform_img_layer d-flex align-items-center position-absolute bottom-0">
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red"
                                          >{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div class="d-flex justify-content-between mb-3">
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Message')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('photos')}</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mb-2 text-start">
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox2">
                                            <input type="checkbox" id="checkbox2" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-3">
                        <h6 class="m-0 cursor_pointer">{t('Suivre 1-ART')}</h6>
                      </li>
                    </ul>
                    <ul class="d-flex justify-content-evenly list-unstyled">
                        <li class="mx-1"><a href='https://www.linkedin.com/company/1-art' style={{color:'#000'}}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-1"><a href='https://www.instagram.com/1_art.co/' style={{color:'#000'}}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-1"><a href='https://www.facebook.com/profile.php?id=61552625361097' style={{color:'#000'}}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-1"><a href='https://www.pinterest.fr/1ArtMonaco/' style={{color:'#000'}}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-1"><a href='https://www.youtube.com/@1-ART_Official' style={{color:'#000'}}><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
      </div>
    </>
  )
}

export default TermsAndService