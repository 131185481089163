import { t } from 'i18next';
import React, { useState } from 'react'
import cookies from "js-cookie";

// Assets
import whishListIcon from '../assets/img/Website Assets/Icons/wishlist.png';
import PanierIcon from '../assets/img/Website Assets/Icons/Buy - blanc.png';
import SearchIcon from '../assets/img/Website Assets/Icons/loupe (2).png';
import FlagIcon from '../assets/img/Website Assets/Icons/France.png'
import { Link, useNavigate } from 'react-router-dom';

const Custom_dropdown = ({isProductOwner}) => {
  const navigate = useNavigate()
  const temp = localStorage.getItem('user');
  const auth = JSON.parse(temp);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLang = (lang) => {
    cookies.set("i18next", lang);
    window.location.reload();
  };

  return (
    <div className="custom-dropdown">

      {isProductOwner && auth?.id ?
      <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/account">
      <button className="toggle-btn" onClick={toggleDropdown}>
        {auth?.firstName.charAt(0) + '' + auth?.lastName.charAt(0)}
      </button>
      </Link>
      : window.location.pathname === '/collection' ? 
      <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/account">
      <button className="toggle-btn" onClick={toggleDropdown}>
        {auth?.firstName.charAt(0) + '' + auth?.lastName.charAt(0)}
      </button>
      </Link>:
      null
}
      {/* {isOpen && (
        <div className="dropdown-content position-absolute p-5">
          <i class='fa-solid fa-xmark fw-bold text-danger cursor_pointer fs-5 d-flex justify-content-end' onClick={toggleDropdown}></i>
          <div className='methew-dropdown d-flex flex-column align-items-center my-3'>
            <h3 className='name d-flex justify-content-center align-items-center'>{auth?.firstName.charAt(0) + '' + auth?.lastName.charAt(0)}</h3>
            <p>{auth?.firstName + ' ' + auth?.lastName ??  'Matthew Tippetts'}</p>
          </div>
          <div class="dropdown d-flex justify-content-center mb-4">
            <button
              class="btn btn- dropdown-toggle bg-tranparent"
              type="button"
              id="dropdownMenu2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
              ><img
                  src={FlagIcon}
                  class="w_icons_navbar_flag"
                  alt="flag"
                /></span>{" "}
              <span className='fs-4'>{"/"}</span> {"€"} {cookies.get("i18next") === 'fr' ? 'French' : 'English'}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <li>
                <button class="dropdown-item" type="button" onClick={() => changeLang("en")}>
                  English
                </button> 
              </li>
              <li>
                <button class="dropdown-item" type="button" onClick={() => changeLang("fr")}>
                  French
                </button>
              </li>
            </ul>
          </div>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <button className='btn-wish'>
              <img src={whishListIcon} class="w_icons_views me-2" />
              Wishlist
            </button>
            <button className='btn-cart'>
              <img src={PanierIcon} class="w_icons_views me-2" />
              Mon Panier
            </button>
          </div>
          <button className='btn-ma-collect' onClick={()=>navigate('/collection')}>
            Ma Collection
          </button>
        </div>
      )} */}
    </div>
  )
}

export default Custom_dropdown